import * as React from 'react';

import Modal from 'antd/lib/modal';
import { MailOutlined } from '@ant-design/icons';
import Space from 'antd/lib/space';
import Input, { InputProps } from 'antd/lib/input';

import RequestComponent, { RequestData } from '@common/react/smart components/RequestComponent';
import Button from '@common/react/components/Forms/Button';
import { ErrorMessageComponent, SuccessMessageComponent } from '@common/react/components/Messages/MessageComponent';

interface OwnProps extends InputProps {
	allowResend?: boolean;
	userId?: number;
	minified?: boolean;
	email: string;
}

interface EmailResponse {
	email: string;
}

export const ResendEmailButton: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		allowResend = false,
		userId = -1,
		minified = false,
		email,
		...domProps
	} = props;

	const button = (isLoading: boolean, action: () => void) => (
		<Button
			title="Resend Confirmation Email"
			loading={isLoading}
			type={minified ? 'text' : 'default'}
			size={minified ? 'small' : undefined}
			onClick={() => Modal.confirm({
				title: `Send confirmation email to ${email}?`,
				okText: 'Send',
				onOk: action,
			})}
			disabled={!allowResend || userId <= 0}
			icon={<MailOutlined />}
		>
			{minified ? 'Resend Confirmation Email' : null}
		</Button>
	);

	return (
		<RequestComponent endpoint="resendConfirmationEmail" requestParams={{ id: userId }}>
			{
				(data: RequestData<EmailResponse>) => (
					<>
						<SuccessMessageComponent message={data.item ? `Confirmation email was resent to ${data.item.email}` : null} />
						<ErrorMessageComponent message={data.error}>
							{
								(minified
								&& button(data.isLoading, data.request))
								||								(
									<Space.Compact block style={props.style}>
										<Input {...domProps} />
										{button(data.isLoading, data.request)}
									</Space.Compact>
								)
							}
						</ErrorMessageComponent>
					</>
				)
			}
		</RequestComponent>
	);
};
