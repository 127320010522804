import { Nullable } from '@common/typescript/objects/Nullable';

export enum RequestStatus {
	Failure = 0,
	Success = 1,
}

export enum Gender {
	Male = 1,
	Female = 2
}

export enum AftercareType {
	Private = 0,
	Communal = 1
}

export enum AshesReturnLocation {

	ReturnToVeterinarian = 0,

	ReturnToOwner = 1,

	ReturnToClinic = 2,

	Pickup = 3,

	Other = 4
}

export interface Log {
	id: number;
	wingsPetId: number | null;

	incomingTime: number;
	sendingTime: Nullable<number>;
	appointment: Appointment;
	appointmentId: number;
	requestStatus: RequestStatus;
	requestResult: string;
}

export interface Appointment {
	id: number;

	appointmentId: number;

	aftercareType: AftercareType;

	crematoryToken: string;

	ashesReturnLocation: Nullable<AshesReturnLocation>;

	engraving: string;

	pickupLocation: Nullable<Location>;
	returnLocation: Nullable<Location>;

	pet: PetNote;
	petId: number;

	owner: Nullable<Owner>;
	ownerId: number;

	notes: Array<Note>;
}

export interface Location {
	id: number;

	externalId: number;

	name: string;

	addressLine1: string;
	addressLine2: string;

	city: string;
	state: string;
	zip: string;

	contactPhoneNumber: string;
}

export interface Owner {
	id: number;

	ownerId: number;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	zip: string;
}

export interface PetNote {
	id: number;
	petId: number;

	name: string;

	petSpecieId: number | null;
	petSpecieName: string;

	petBreedId: number | null;
	petBreedName: string;

	isMixed: boolean;

	color: string;
	gender: Gender;
	age: number;
	weight: number;
}

export interface Note {
	id: number;
	noteId: number;
	text: string;
	date: number;
}
