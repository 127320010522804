import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import { PlusOutlined } from '@ant-design/icons';
import { BaseButtonProps } from 'antd/lib/button/button';

interface AdaptiveAddButtonProps extends BaseButtonProps {
	itemName: string;
	type?: 'link' | 'default' | 'ghost' | 'primary' | 'dashed';
	className?: string;
	onClick: () => void;
	withIcon?: boolean;
	disabled?: boolean;
}

export const AdaptiveAddButton: React.FC<AdaptiveAddButtonProps> = ({
	itemName,
	onClick,
	className,
	type = 'primary',
	withIcon,
	disabled,
	...props
}: AdaptiveAddButtonProps) => {
	const buttonAddName = `Add ${itemName}`;

	return (
		<>
			<span className="btn--add-with-icon">
				<Tooltip title={buttonAddName}>
					<Button
						{...props}
						type={type}
						icon={<PlusOutlined />}
						onClick={onClick}
						className={className}
						disabled={disabled}
					/>
					<span className="visually-hidden">{buttonAddName}</span>
				</Tooltip>
			</span>
			<span className="btn--add-with-word">
				<Button
					{...props}
					type={type}
					icon={withIcon ? <PlusOutlined /> : undefined}
					onClick={onClick}
					className={className}
					disabled={disabled}
				>
					<span>{buttonAddName}</span>
				</Button>
			</span>
		</>
	);
};
