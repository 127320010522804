import * as React from 'react';

import { useHistory, RouteComponentProps } from 'react-router-dom';

import Table, { ColumnProps } from 'antd/lib/table';

import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';
import { useTableRemoteSorter } from '@common/react/hooks/RemoteData/useTableRemoteSorter';
import TableDataProvider, { TableData } from '@common/react/smart components/Table/TableDataProvider';
import { listWithModal, listWithModalHOCProps } from '@common/react/components/List/ListWithModal/ListWithModal';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { WithId } from '@common/typescript/objects/WithId';

import { ActionButtons } from '@app/components/UI/Buttons/ActionButtons';
import { Filters, useFilters } from '@app/components/UI/Filters/FilterHook';
import { FiltersComponent } from '@app/components/UI/Filters/FilterComponent';
import { ListHeader } from '@app/components/UI/Header/PageHeaders/ListHeader';
import { ActionFactory } from '@app/components/Utils/ActionFactory';
import { TotalQuantity } from '@app/components/UI/Filters/TotalQuantity';

interface CopyProps {
	reload?: () => void;
	reqParams?: Record<string, unknown>;
	copyEndpoint?: string;
	successMessage?: string;
	errorMessage?: string;
	title?: string;
	content?: (modalState: boolean) => React.ReactNode;
	buttonCopyName?: string;
	copyModalWidth?: string;
	okButtonProps?: Record<string, unknown>;
}

interface ItemsPageProps<T extends WithId> {
	getListEndpoint: string;
	requestAdditionalParams?: Record<string, unknown>;
	pageTitle: React.ReactNode;
	itemName: string;
	columns: Array<ColumnProps<T>>;
	path?: string;
	showAddButton?: boolean;
	withHeaderMargin?: boolean;
	withTotalQuantity?: boolean;
	filterAdditionalParams?: Record<string, unknown>;
	filterNames?: Array<keyof Filters>;
	sorterNames?: Array<string>;
	rowKey?: (record: T) => string | string;

	actions?: ActionFactory<T>;
	defaultParams?: Record<string, unknown>;
	expandedRow?: {
		render: (record: T, index: number, indent: number, expanded: boolean) => React.ReactNode;
		expandable: (record: T) => React.ReactNode;
	};
	onRow?: (record: T, index?: number) => React.HTMLAttributes<T> | React.TdHTMLAttributes<T>;
	extraBtn?: React.ReactNode;
	copyProps?: (data: TableData<T>) => CopyProps;
	onLoaded?: (data: TableData<T>) => void;
}

type Props<T extends WithId> = ItemsPageProps<T> & listWithModalHOCProps & RouteComponentProps<{id: string, crematoryId: string}>;

function getActionsColumn<T extends WithId>(
	reload: () => void,
	actions?: ActionFactory<T>,
): Array<ColumnProps<T>> {
	if (actions === undefined) return [];

	return TableBuilder.shape<T>()
		.addColumn({
			title: 'Actions',
			width: '100px',
			align: 'center',
			render: (text, record) => (
				<ActionButtons
					actions={actions({ reload, record })}
				/>
			),
		}).build();
}

const WithPageWrapInner = <T extends WithId>(props: Props<T>) => {
	const {
		columns,
		pageTitle,
		getListEndpoint,
		itemName,
		requestAdditionalParams = {},
		path,
		showAddButton = true,
		withHeaderMargin = false,
		withTotalQuantity = true,
		filterAdditionalParams = {},
		filterNames = [],
		sorterNames = props.filterNames,

		actions,
		defaultParams = {},
		expandedRow,
		extraBtn,
		rowKey = 'id',
		onRow,
		copyProps,
	} = props;

	const sorter = useTableRemoteSorter<T>();
	const history = useHistory();

	const [filters, onChange, setQueryParams, queryParams, clearQueryParams, onDeselect] = useFilters();

	const reqParams = React.useMemo(() => ({
		filters: {
			...queryParams,
			...filterAdditionalParams,
		},
		...sorter.sorter,
		...requestAdditionalParams,
	}), [queryParams, sorter.sorter]);

	return (
		<>
			<TableDataProvider
				endpoint={getListEndpoint}
				requestParams={reqParams}
				reloadOnParamsChanged
			>
				{(data: TableData<T>) => {
					if (data && !data.isLoading) props.onLoaded?.(data);

					return (
						<>
							<ListHeader
								name={pageTitle}
								showAddButton={showAddButton}
								itemName={itemName}
								handleAddNewClick={() => history.replace(path ?? '')}
								withHeaderMargin={withHeaderMargin}
								extraBtn={extraBtn}
								{...copyProps?.(data)}
							/>

							{filterNames.length > 0 && (
								<FiltersComponent
									filters={filters}
									onChange={onChange}
									setQueryParams={setQueryParams}
									clearQueryParams={clearQueryParams}
									onDeselect={onDeselect}
									filterNames={filterNames}
									sorterNames={sorterNames}
									isLoading={data.isLoading}

									sorter={sorter}
									defaultParams={defaultParams}
								/>
							)}
							<TotalQuantity count={data.pagination.total} withTotalQuantity={withTotalQuantity} />
							{data.error && <AlertMessage message={data.error} className="table-alert" />}

							<Table
								columns={[
									...columns,
									...getActionsColumn<T>(
										data.reload,
										actions,
									),
								]}
								dataSource={data.items.list}
								pagination={data.pagination}
								loading={data.isLoading}
								onChange={((pagination, filters, sorterResult) => {
									sorter.setSorter(sorterResult);
								})}
								rowKey={rowKey}
								className="table-mobile"
								onRow={onRow}
								expandable={{
									expandedRowRender: expandedRow?.render,
									childrenColumnName: 'child',
								}}
							/>

						</>
					);
				}}
			</TableDataProvider>

		</>
	);
};

export const WithPageWrap = listWithModal(WithPageWrapInner) as unknown as <T extends WithId>(props: ItemsPageProps<T>) =>
	React.ReactElement<Props<T>>;
