import React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';
import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { WithChildren } from '@app/objects/WithChildren';

interface TableContainerProps extends WithChildren {
	error?: Nullable<string>;
}

export const TableContainer: React.FC<TableContainerProps> = ({ children, error }: TableContainerProps) => (
	<Stack direction={StackDirection.Vertical}>
		{error && <AlertMessage message={error} style={{ margin: 0 }} />}
		{children}
	</Stack>
);
