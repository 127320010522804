import React from 'react';

import Input from 'antd/lib/input';
import { FieldProps } from 'formik';
import Space from 'antd/lib/space';
import { Col, Row } from 'antd/lib/grid';

import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { discountLimit, discountUnit } from '@app/components/UI/Inputs/LocalSelectOptions';
import { FormikField } from '@app/components/Forms/FormikField';

export const DiscountComponent: React.FC = () => (
	<Space direction="vertical" size="middle">
		<FormikField
			fieldName="name"
			title="Name*"
			inputProps={{ type: 'text' }}
		/>

		<Row gutter={[30, 16]}>
			<Col xs={24} sm={24} md={8}>
				<FormikField
					fieldName="unit"
					title="Unit*"
					render={({ field, form }) => (
						<LocalSelect
							value={field.value}
							onChange={(value: number) => form.setFieldValue(field.name, value, false)}
							options={discountUnit}
							deselectType=""
							filterName="unit"
							fieldName=""
							placeholder="Select the unit"
							allowClear={false}
						/>
					)}
				/>
			</Col>
			<Col xs={24} sm={24} md={8}>
				<FormikField
					fieldName="applyTo"
					title="Apply To"
					render={({ field, form }) => (
						<LocalSelect
							value={field.value ?? -1}
							onChange={(value: number) => form.setFieldValue(field.name, value >= 0 ? value : null, false)}
							options={discountLimit}
							deselectType=""
							filterName="Apply To"
							fieldName=""
							placeholder="Select price type to restrict discount to"
							allowClear={false}
						/>
					)}
				/>
			</Col>
			<Col xs={24} sm={24} md={8}>
				<FormikField
					fieldName="value"
					title="Value*"
					inputProps={{
						type: 'number',
						min: 0,
						inputMode: 'decimal',
					}}
				/>
			</Col>
		</Row>

		<FormikField
			fieldName="description"
			title="Description"
			render={({ field, form }: FieldProps) => (
				<Input.TextArea
					value={field.value}
					onChange={(event) => form.setFieldValue(field.name, event.target.value)}
				/>
			)}
		/>
	</Space>
);
