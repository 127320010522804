import * as React from 'react';

import { WithAddress } from '@app/objects/WithAddress';
import { getStateName } from '@app/utilities/address';

interface AddressViewProps {
	address: WithAddress;
	container?: React.ComponentType<React.PropsWithChildren<AddressView>>;
}

interface AddressView {
	// eslint-disable-next-line
	children: string;
}

function formAddressString(address: WithAddress): string {
	const array: Array<string> = [
		address.streetAddress || 'No street address provided',
		address.streetAddress2,
		address.city || 'No city provided',
		getStateName(address) || 'No state provided',
		address.zip || 'No zip provided',
	];

	return array.filter((q) => !!q).join(', ');
}

export const AddressView: React.FC<AddressViewProps> = (props: AddressViewProps) => {
	const Container = props.container || (({ children }: React.PropsWithChildren<AddressView>) => <div>{children}</div>);
	const address: string = formAddressString(props.address);

	return <Container>{address}</Container>;
};

AddressView.defaultProps = {
	container: ({ children }: React.PropsWithChildren<AddressView>) => (
		<div>
			{children}
			<a
				target="_blank"
				href={`https://maps.google.com/maps?q=${children}`}
				rel="noreferrer"
				className="address-link"
			>
				Show on map
			</a>
		</div>
	),
};
