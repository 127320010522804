import React from 'react';

import { alertMessage, MessageType } from '@app/utilities/alert';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { ModalControls } from '@app/hooks/Editors/useModal';
import { useRequest } from '@app/hooks/useRequest';

interface CopyModalProps {
	modal: ModalControls;
	modalWidth?: string;

	copyEndpoint?: string;
	errorMessage?: string;
	successMessage?: string;
	title?: string;
	content?: () => React.ReactNode;
	reqParams?: Record<string, unknown>;
	reload?: () => void;
	okButtonProps?: Record<string, unknown>;
}

export const CopyModal: React.FC<CopyModalProps> = (props: CopyModalProps): JSX.Element => {
	const {
		copyEndpoint = '',
		errorMessage = '',
		successMessage = '',
		okButtonProps,
		modalWidth,
		reqParams,
		reload,
		modal,
	} = props;
	const request = useRequest(copyEndpoint, undefined, { requestOnMount: false });

	return (
		<ModalWrapper
			title={props.title ?? ''}
			isOpened={modal.state}
			onOk={() => {
				request.reload(reqParams)
					.then(() => {
						if (reload) reload();

						modal.close();
						alertMessage(MessageType.success, successMessage);
					})
					.catch((error: string) => alertMessage(MessageType.error, `${errorMessage} ${error}`));
			}}
			okText="Copy"
			onCancel={modal.close}
			width={modalWidth}
			okButtonProps={{ ...okButtonProps, loading: request.loading }}
		>
			{props.content?.()}
		</ModalWrapper>
	);
};
