import { TFunction } from 'react-i18next';

import { WithId } from '@common/typescript/objects/WithId';
import { Pagination } from '@common/react/smart components/Table/TableDataProvider';

import { RemoteDataEnvelope } from '@app/hooks/RemoteDataProvider/RemoteDataProvider';
import { Filters } from '@app/components/UI/Filters/FilterHook';
import { toUpperCase } from '@app/components/Utils/Utils';
import { request } from '@app/components/Api';
import { deleteConfirmation } from '@app/components/UI/Modal/DeleteConfirmation';
import { alertMessage, MessageType } from '@app/utilities/alert';

export function reload<T extends WithId, TParams>(data: RemoteDataEnvelope<T, TParams>, values?: Partial<Filters>, reqParams?: TParams) {
	const currentPage = data.pagination.page;

	if (currentPage === 0) {
		data.fetch({ ...reqParams, filters: values } as TParams);
	} else {
		data.onPageChange(0);
	}
}

export function offset(pagination?: Pagination) {
	if (!pagination) return undefined;

	const { total, pageSize, current } = pagination;
	const count = (total - 1) % pageSize;
	const currentPage = current !== 1 ? current - 1 : current;

	const isLastOnPage = count === 0 && current === Math.ceil(total / pageSize);

	return isLastOnPage ? currentPage * pagination.pageSize - 10 : undefined;
}

export const handleDelete = <T extends WithId>
	(
		t: TFunction,
		itemName: string,
		deleteEndpoint: string,
		requestParams: Record<string, unknown> | undefined,
		reload: (count?: number) => void,
		id: number,
		pagination?: Pagination,
		errorSettings?: (error: string) => void,
		label?: string,
	):
	void => {
	const upperCasedItem = toUpperCase(itemName);

	const callback = () => {
		return request<T>(deleteEndpoint, { id, deleted: true, ...requestParams })
			.then(() => {
				reload(offset(pagination));
				alertMessage(MessageType.success, `${t('messages.type.success')}! ${upperCasedItem} ${t('messages.text.delete')}`);
			})
			.catch((err) => (errorSettings ? errorSettings?.(err) : alertMessage(MessageType.error, `${t('messages.type.error')}! ${err}`)));
	};

	return deleteConfirmation({
		title: itemName,
		modalTitle: label,
		callback,
		t,
	});
};
