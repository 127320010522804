import React from 'react';

import Search from 'antd/lib/input/Search';
import Table from 'antd/lib/table';
import { useFormikContext } from 'formik';

import TableDataProvider, { TableData } from '@common/react/smart components/Table/TableDataProvider';

import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { InventoryParentsSelect } from '@app/components/UI/Inputs/InventoryParentsSelect';
import { ProductSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { PriceKind } from '@app/objects/Price';
import { getCheckedColumn, handleChecked, getColumns } from '@app/components/Various/PriceEditor/PriceEditorComponents/ServicePrice/ModalTable';

interface ProductTableProps {
	params: ProductSelectFilter;
	priceKind: PriceKind;
}

export const ProductTable: React.FC<ProductTableProps> = (props: ProductTableProps) => {
	const { values, setFieldValue } = useFormikContext<FormValues>();
	const [query, setQuery] = React.useState<string>('');
	const [parents, setParents] = React.useState<Array<number>>([]);

	const reqParams = React.useMemo(() => ({
		filters: {
			...props.params,
			fullName: query,
			ancestorId: parents,
		},
		withDefault: true,
	}), [query, props.params, parents]);

	return (
		<TableDataProvider endpoint="inventoryItemList" requestParams={reqParams} reloadOnParamsChanged>
			{(data: TableData<InventoryItem>) => (
				<Stack direction={StackDirection.Vertical}>
					<Stack direction={StackDirection.Horizontal}>
						<Search
							allowClear
							onChange={(event) => {
								if (event.target.value === '' && event.type === 'click' && setQuery) {
									setQuery('');
								}
							}}
							placeholder="Search by Name"
							onSearch={(e: string) => {
								setQuery(e);
								setFieldValue('filters.name', e);
							}}
							style={{ padding: '0 0 10px' }}
							enterButton
						/>

						<InventoryParentsSelect
							value={parents}
							onChange={(values?: Array<number>) => {
								const ids = values ?? [];
								setParents(ids);
								setFieldValue('filters.ancestorId', ids);
							}}
							category={props.priceKind === PriceKind.UrnPrice ? [ItemCategory.UrnCategory] : [ItemCategory.ProductCategory]}
							multiple
							className="search-field search-field--dense"
							placeholder="Select Group"
						/>
					</Stack>

					<Table
						columns={[...getCheckedColumn('inventoryItemId'), ...getColumns('fullName')]}
						dataSource={data.items.list}
						pagination={{ ...data.pagination, hideOnSinglePage: true }}
						loading={data.isLoading}
						childrenColumnName="child"
						rowKey="id"
						className="table-mobile small-padding-table"
						onRow={(record) => ({
							onClick: () => {
								const items = values.inventoryItemId as Array<number> ?? [];
								const isChecked = items.includes(record.id);
								const onChange = (value: Array<number>) => setFieldValue('inventoryItemId', value);

								handleChecked(!isChecked, values.selectAll, record.id, items, onChange);
							},
						})}
					/>
				</Stack>
			)}
		</TableDataProvider>
	);
};
