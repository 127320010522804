import * as React from 'react';

import {WithId} from '@common/typescript/objects/WithId';

export interface TableControls<T extends WithId> {
	rowSelection: {
		onChange: (selectedRows: Array<number>) => void;
		selectedRowKeys: Array<number>;
	};
}

interface TableControllerProps<T extends WithId> {
	children: (controls: TableControls<T>) => JSX.Element;
}

export const TableController = <T extends WithId>(props: TableControllerProps<T>) => {
	const [selected, setSelected] = React.useState<Array<number>>([]);

	const controls: TableControls<T> = React.useMemo(() => ({
		rowSelection: {
			onChange: setSelected,
			selectedRowKeys: selected,
		},
	}), [selected]);

	return props.children(controls);
};
