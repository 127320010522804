import { GroupOption } from '@app/components/UI/Inputs/LocalSelect';
import { StatusFunctionMeta } from '@app/components/UI/Inputs/LocalSelectOptions';

export const statusFunctionGroup = (items: Array<StatusFunctionMeta>): Array<GroupOption> => {
	const result = new Map<string, GroupOption>();

	items.forEach((item: StatusFunctionMeta) => {
		const key = item.group;

		if (result.has(key) && result.get(key)) {
			const oldValue = result.get(key)?.options;

			if (oldValue !== undefined) result.set(key, { title: key, options: [...oldValue, item] });
		} else {
			result.set(key, { title: key, options: [item] });
		}
	});

	return Array.from(result, ([, value]) => value);
};
