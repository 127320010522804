import clsx from 'clsx';
import * as React from 'react';

import MaskedInput from 'react-text-mask';

interface OwnProps {
	fieldName: string;
	title: string;
	placeholder: string;
	className?: string;
	onChange: (text: string) => void;
	onDeselect: (fieldName: string, type: string, id?: number,) => void;
	mask: Array<string | RegExp>;
	guide: boolean;
	withFieldName?: boolean;
	value: string | undefined;
}

export const MaskedSearch: React.FC<OwnProps> = ({
	placeholder,
	onChange, fieldName,
	className, onDeselect, title, mask, guide, withFieldName, value,
}):JSX.Element => {
	const [query, setQuery] = React.useState<string>('');
	const selectClassName = (value || query.length > 0)
		? 'select-with-inner-label--opened' : 'select-with-inner-label--closed';

	return (
		<div className={className}>
			{withFieldName && <span className="field-name">{title}</span>}
			<MaskedInput
				className={clsx('form-control', selectClassName)}
				style={{ paddingLeft: '11px' }}
				mask={mask}
				placeholder={placeholder}
				guide={guide}
				onChange={(e) => {
					setQuery(e.target.value);
					if (e.target.value === '') {
						onDeselect(fieldName, 'text');
					} else {
						onChange(e.target.value);
					}
				}}
			/>
		</div>
	);
};
