import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Nullable } from '@common/typescript/objects/Nullable';
import { DatePicker } from '@common/react/components/Forms/Pickers/index';

import { dateFormat as defaultDateFormat, ResultDatesRange } from '@app/components/Utils/Utils';

import '@app/scss/ui/filters.scss';
import '@app/scss/ui/date-select.scss';

const { RangePicker } = DatePicker;

interface OwnProps {
	onChange: (from: number | undefined, to: number | undefined) => void;
	onDeselect: (fieldName: string, type: string, id?: number) => void;
	value: {from: number | undefined, to: number | undefined} | undefined;
	fieldName: string;
	filterName: string;
	className?: string;
	withFieldName?: boolean;
	disabledDate?: (currentDate: dayjs.Dayjs | null) => boolean;
	format?: string;

	styles?: React.CSSProperties;
}

type DateRangeProps = { from: dayjs.Dayjs | null, to: dayjs.Dayjs | null };
type RangeValue<T> = [T | null, T | null]
function getValue(value: number | undefined): dayjs.Dayjs | undefined {
	return value !== undefined ? dayjs(value) : undefined;
}

function getRange(value: ResultDatesRange | undefined): RangeValue<dayjs.Dayjs> {
	return [getValue(value?.from), getValue(value?.to)] as RangeValue<dayjs.Dayjs>;
}

export const DateSelect: React.FC<OwnProps> = ({
	onChange, onDeselect, value, fieldName,
	filterName, className = '', withFieldName = true, styles,
	disabledDate, format,
}): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: 'filters' });
	const [date, setDate] = React.useState<DateRangeProps>({ from: null, to: null });
	const dateFormat = format ?? defaultDateFormat;

	return (
		<div>
			<div className={clsx('search-field--date-desktop is-relative', className, value && (value.from || value?.to) ? 'with-inner-label--opened' : 'with-inner-label--closed')}>
				{withFieldName && <span className="ant-select-selection__placeholder">{fieldName}</span>}
				<RangePicker
					format={dateFormat}
					value={getRange(value)}
					onChange={(dates: RangeValue<dayjs.Dayjs> | null) => {
						if (!dates) {
							onDeselect(filterName, 'text');
						} else { onChange(dates[0]?.valueOf(), dates[1]?.valueOf()); }
					}}
					style={styles}
					showTime={false}
					disabledDate={disabledDate}
				/>
			</div>
			<div className={`${className} search-field--date-mobile`}>
				<span className="field-name">{fieldName} {t('date-from')}</span>
				<DatePicker
					format={dateFormat}
					onChange={(pickedDate: dayjs.Dayjs | null) => {
						setDate((prevState) => ({ from: pickedDate, to: prevState?.to }));
						onChange(pickedDate?.valueOf(), date.to?.valueOf());
					}}
					showTime={false}
					className="form-group"
				/>
				<div className={className}>
					<span className="field-name">{fieldName} {t('date-to')}</span>
					<DatePicker
						format={dateFormat}
						onChange={(pickedDate: dayjs.Dayjs | null) => {
							setDate((prevState) => ({ from: prevState?.from, to: pickedDate }));
							onChange(date.from?.valueOf(), pickedDate?.valueOf());
						}}
						showTime={false}
					/>
				</div>
			</div>
		</div>
	);
};

interface DateTimeSelectProps {
	value: Nullable<dayjs.Dayjs>;
	onChange: (value: Nullable<dayjs.Dayjs>) => void;
	placeholder: string;

	fieldName?: string;
	showTime?: boolean;
	format?: string;
	className?: string;
	styles?: React.CSSProperties;
}

export const DateTimeSelect: React.FC<DateTimeSelectProps> = ({
	value, onChange, fieldName, className = '', styles, format = 'MM/DD/yyyy HH:mm', showTime = true, placeholder,
}) => (
	<div>
		<div className={className}>
			{fieldName && <span className="field-name">{fieldName}</span>}
			<DatePicker
				value={value}
				showTime={showTime}
				format={format}
				placeholder={placeholder}
				onChange={(date: dayjs.Dayjs | null) => onChange(date)}
				style={styles}
			/>
		</div>
	</div>
);
