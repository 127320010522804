import React from 'react';
import { Field, FieldProps } from 'formik';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PriceKind } from '@app/objects/Price';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { noUrnId } from '@app/components/Various/PriceEditor/services';

interface NoItemCheckboxProps {
	priceId: number;
	priceKind: PriceKind;
	noItem: boolean;
	onChange: (value: boolean) => void;
}

export const NoItemCheckbox: React.FC<NoItemCheckboxProps> = ({
	priceId, priceKind, noItem, onChange,
}: NoItemCheckboxProps) => (
	<Field name="inventoryItemId">
		{(fieldProps: FieldProps<FormValues>) => {
			if (priceId > 0) return null;

			return (
				<FormikInput
					fieldProps={fieldProps}
					title={`Price For No ${priceKind === PriceKind.UrnPrice ? 'Urn' : 'Product'}`}
					containerClassName=""

					render={({ field, form }: FieldProps<boolean, FormValues>) => (
						<div className="is-relative">
							<Checkbox
								checked={noItem}
								onChange={(value: CheckboxChangeEvent) => {
									if (value.target.checked) {
										form.setValues({
											...form.values,
											filters: {
												name: '',
												ancestorId: [],
											},
											inventoryItemId: [noUrnId],
											selectAll: false,
										});
									} else {
										form.setFieldValue(field.name, []);
									}

									onChange(value.target.checked);
								}}
							>
								{`Select this to add price for No ${priceKind === PriceKind.UrnPrice ? 'Urn' : 'Product'}`}
							</Checkbox>
						</div>
					)}
				/>
			);
		}}
	</Field>
);
