import * as React from 'react';

import { Field, FieldProps } from 'formik';

import Alert from 'antd/lib/alert';
import Checkbox from 'antd/lib/checkbox';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ServiceType } from '@app/objects/Pet';
import { ViewMode } from '@app/components/Utils/Utils';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';

type CommunalWarningProps = Omit<BaseSectionProps, 'crematoryId'>
export const CommunalWarning: React.VFC<CommunalWarningProps> = (props: CommunalWarningProps) => (
	<Field name="noAshes">
		{(fieldProps: FieldProps<FormValues>) => (
			<FormikInput
				fieldProps={fieldProps}
				containerClassName=""
				title=""
				render={({ field, form }: FieldProps<FormValues>) => {
					if (form.values.serviceType !== ServiceType.Communal) return null;
					if (props.mode !== ViewMode.edit) return null;
					if (form.values.id >= 0 && form.initialValues.serviceType === form.values.serviceType) return null;
					if (field.value) return null;

					return (
						<div className="form-group" style={{ marginTop: '15px' }}>
							<Alert
								message="Communal cremation doesn't offer ashes return"
								description={(
									<>
										<p className="ashes-return__label">Check the box to confirm</p>
										<Checkbox
											className="ashes-return__checkbox"
											onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
										>
											Confirm ashes won't be returned
										</Checkbox>
									</>
								)}
								type="warning"
							/>
						</div>
					);
				}}
			/>
		)}
	</Field>
);
