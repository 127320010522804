import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableCounterProps {
	children?: number;
}

export const TableCounter: React.FC<TableCounterProps> = ({ children }: TableCounterProps) => {
	const { t } = useTranslation('translation');

	return (
		<span className="filters-quantity">
			{children} {t('filters.quantity')}
		</span>
	);
};
