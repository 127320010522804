import * as React from 'react';

import { TableRemoteFilterData } from '@common/react/smart components/Table/TableRemoteFilter';
import { WithId } from '@common/typescript/objects/WithId';

export function useTableRemoteFilter<T extends WithId>(): TableRemoteFilterData<T> {
	const [filters, setFilters] = React.useState({});

	return React.useMemo(() => ({
		filters,
		onFilter: setFilters,
	}), [filters]);
}
