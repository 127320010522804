import * as React from 'react';

import { BackButton } from '@app/components/UI/Header/PageHeaders/BackButton';

interface PageHeaderProps {
	name: string | JSX.Element;
	backPath?: string;
}

export const PageHeading: React.FC<PageHeaderProps> = (props: PageHeaderProps): JSX.Element => {
	return (
		<div>
			<BackButton backPath={props.backPath} />
			{typeof props.name === 'string' ? <h1 className="header">{props.name}</h1> : props.name}
		</div>
	);
};
