import React from 'react';

import Popover from 'antd/lib/popover';
import {
	Field,
	FieldArray,
	FieldArrayRenderProps,
	FieldProps,
} from 'formik';
import { BookOutlined } from '@ant-design/icons';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import { FilePreview } from '@common/react/components/Forms/Upload/FilePreview';
import { ModalPreview } from '@common/react/components/Forms/Upload/ModalPreview';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { Card, CardSize } from '@app/components/UI/Cards/Card';
import { PetFile } from '@app/objects/PetFile';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { UploadDocuments } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DocumentsSection/UploadDocuments';

export const DocumentsList: React.FC<BaseSectionProps> = ({ mode }: BaseSectionProps) => {
	const [file, setFile] = React.useState<Nullable<FileInterface>>(null);

	return (
		<>
			<FieldArray
				name="files"
				render={(arrayHelper: FieldArrayRenderProps) => (
					<Card
						name="Documents"
						size={CardSize.Medium}
						mode={mode}
						fullWidth

						editContent={(
							<Field name="files">
								{(fieldProps: FieldProps<FormValues>) => (
									<FormikInput
										fieldProps={fieldProps}
										title=""
										containerClassName=""
										render={({ field, form } : FieldProps<Array<PetFile>, FormValues>) => (
											<UploadDocuments
												petId={form.values.id}
												items={field.value}

												onChange={(items: Array<PetFile>) => form.setFieldValue(field.name, items, false)}
												onChangeFile={setFile}

												type="pet"
												maxSizeCaption="File should not exceed 20 mb"
											/>
										)}
									/>
								)}
							</Field>
						)}

						viewContent={(
							<ul className="upload-list">
								{arrayHelper.form.values.files.length
									? arrayHelper.form.values.files.map((item: PetFile) => (
										<li className="upload-list__" key={item.fileId}>
											<div style={{ width: '256px' }}>
												<FilePreview
													file={item.file}
													onPreview={() => setFile(item.file)}
													download
												/>
												{item.text && (
													<Popover
														placement="bottom"
														content={item.text}
														overlayStyle={{
															maxWidth: '200px',
														}}
													>
														<div className="file-preview__caption">
															<BookOutlined /><span>Notes about file</span>
														</div>
													</Popover>
												)}
											</div>
										</li>
									))
									: 'There are no documents'}
							</ul>
						)}
					/>
				)}
			/>
			<ModalPreview file={file} onClose={() => setFile(null)} />
		</>
	);
};
