import { generatePath } from 'react-router-dom';

import { RoutePaths } from '@app/utilities/RoutePathVars';
import { ViewMode } from '@app/components/Utils/Utils';

export const NEW_PET_ID = -1;
export const validationMessage = 'Required field';
export const petListPath = generatePath(RoutePaths.pets, { tab: 'pet-list' });
export const store = 'pets';
export const path = 'petList';
export const perPage = 12;

export const getClinicPetViewPath = (id: number): string => generatePath(RoutePaths.petEditor, { id, type: ViewMode.view });
export const getClinicPetEditPath = (id: number): string => generatePath(RoutePaths.petEditor, { id, type: ViewMode.edit });
