import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
	FieldArrayRenderProps,
	FieldArray,
	useFormikContext,
} from 'formik';

import Table from 'antd/lib/table';

import { List } from '@common/typescript/objects/List';

import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import {
	CardSize,
	Card,
	ListComponent,
} from '@app/components/UI/Cards/Card';
import { ViewMode } from '@app/components/Utils/Utils';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { PriceType, Price, PriceKind } from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { useModal } from '@app/hooks/Editors/useModal';
import { ProductModal } from '@app/components/Pages/PetEditor/ProductsModal';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { Preview } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/Preview';
import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { getColumns } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProductsSection';
import { PriceListParams, getDisableModal, getPetPrice } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/utils';
import { useCrematory } from '@app/hooks/useCrematory';

import '@app/scss/ui/urn-preview.scss';

function getProductsList(products: Array<PetPrice>) {
	const items = products.filter((q: PetPrice) => !q.removed);

	return getPetPrice(items);
}

export const ProductList: React.FC<BaseSectionProps> = ({ mode, crematoryId }: BaseSectionProps) => {
	const modal = useModal(false);
	const { t } = useTranslation();
	const crematory = useCrematory();
	const showPricesToClinic = crematory?.showPricesToClinic ?? false;
	const translate = (key: string) => t(iKey(key));
	const { values, setFieldValue } = useFormikContext<FormValues>();

	const [preview, setPreview] = React.useState<string | undefined>(undefined);
	const priceRequest = useRequest<List<Price>, PriceListParams>('priceList', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (mode === ViewMode.view) return;
		if (crematoryId === -1) return;

		priceRequest.reload({
			clinicId: null,
			priceType: PriceType.Wholesale,
			serviceType: ServiceType.Private,
			withInventoryItem: true,
			count: 100,
			filters: {
				priceKind: [PriceKind.ProductPrice],
			},
		});
	}, [crematoryId, mode]);

	if (!priceRequest.item?.list.length && !values.products.length) return null;

	return (
		<>
			<FieldArray
				name="products"
				render={(arrayHelper: FieldArrayRenderProps) => (
					<Card
						name="Products"
						size={CardSize.Medium}
						mode={mode}
						fullWidth
						withBtn={mode === ViewMode.edit}
						onClick={modal.open}
						disabled={getDisableModal(arrayHelper.form.values.serviceType)}

						editContent={(
							<Table
								columns={getColumns(translate, setPreview, showPricesToClinic)}
								dataSource={arrayHelper.form.values.products.filter((q: PetPrice) => !q.removed)}
								size="small"
								rowKey="clientId"
								pagination={false}
							/>
						)}

						viewContent={(
							<ListComponent
								list={getProductsList(arrayHelper.form.values.products)}
								placeholder="There are no products"
							/>
						)}
					/>
				)}
			/>
			<ProductModal
				modal={modal}
				prices={priceRequest.item?.list ?? []}

				onChange={(items: Array<PetPrice>) => setFieldValue('products', items)}

				petId={values.id}
				products={values.products.filter((q: PetPrice) => !q.removed)}

				serviceType={values.serviceType ?? ServiceType.Private}
				priceType={PriceType.Wholesale}
			/>
			<Preview image={preview} onClose={() => setPreview(undefined)} />
		</>
	);
};
