import React from 'react';

import { Form, FormikProps } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	PriceKind,
	PriceType,
	Price,
	getPriceKindName,
} from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { PriceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/BasePrice';
import { DeliveryPriceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/DeliveryPrice';
import { DiscountComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/DiscountPrice';
import { EngravingPriceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/EngravingPrice';
import { SpecialServiceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/ServicePrice/SpecialServicePrice';
import { ProductPriceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/ProductPrice/ProductPrice';
import { PickupServicesComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/ServicePrice/PickupServicePrice';
import { RushFeePriceComponent } from '@app/components/Various/PriceEditor/PriceEditorComponents/RushFeePrice';

interface InnerFormProps {
	priceKind: PriceKind;
	serviceType: ServiceType;
	priceType: PriceType;
	item: Nullable<Price>;
	priceId: Nullable<number | string>;

	crematoryId: number;
	clinicId: Nullable<number>;

	modalItemId: number | string;
	visible: boolean;
	needRefresh: boolean;
	setNeedRefresh: (value: boolean) => void;
	formikBag: FormikProps<FormValues>;
	closeDialog: () => void;
}

export const InnerForm: React.FC<InnerFormProps> = (
	{
		modalItemId,
		visible,
		formikBag,
		closeDialog,
		item,
		serviceType,
		priceType,
		clinicId,
		crematoryId,
		priceId,
		priceKind,
		needRefresh,
		setNeedRefresh,
	},
) => (
	<ModalWrapper
		title={`${Number(modalItemId) > 0 ? 'Edit' : 'Add'} ${getPriceKindName(priceKind)}`}
		isOpened={visible}
		onCancel={() => {
			formikBag.resetForm();
			closeDialog();
		}}
		onOk={() => formikBag.handleSubmit()}
		confirmLoading={formikBag.isSubmitting}
		okText={item ? 'Save' : 'Add'}
		width="50%"
		style={{
			maxWidth: '750px',
		}}
	>
		<Form id="location-form">
			{((item && item.specialServiceId) || priceKind === PriceKind.SpecialServicePrice)
				&& (
					<SpecialServiceComponent
						serviceType={serviceType}
						priceType={priceType}
						clinicId={clinicId}
						crematoryId={crematoryId}
						priceId={priceId}
						needRefresh={needRefresh}
						isModalOpen={visible}
						setNeedRefresh={setNeedRefresh}
					/>
				)}
			{priceKind === PriceKind.PickupPrice && (
				<PickupServicesComponent
					serviceType={serviceType}
					priceType={priceType}
					clinicId={clinicId}
					crematoryId={crematoryId}
					priceId={priceId}
					needRefresh={needRefresh}
					isModalOpen={visible}
					setNeedRefresh={setNeedRefresh}
				/>
			)}
			{priceKind === PriceKind.DeliveryPrice && (
				<DeliveryPriceComponent
					clinicId={clinicId}
					serviceType={serviceType}
					priceType={priceType}
					isModalOpen={visible}
				/>
			)}
			{priceKind === PriceKind.BasePrice && <PriceComponent />}
			{
				(priceKind === PriceKind.UrnPrice || priceKind === PriceKind.ProductPrice) && (
					<ProductPriceComponent
						crematoryId={crematoryId}
						clinicId={clinicId}
						serviceType={serviceType}
						priceType={priceType}
						priceKind={priceKind}
						availableForCrematory={Boolean(priceId && !clinicId)}
						needRefresh={needRefresh}
						isModalOpen={visible}
						setNeedRefresh={setNeedRefresh}
					/>
				)
			}
			{priceKind === PriceKind.Discount && <DiscountComponent />}
			{priceKind === PriceKind.Engraving && <EngravingPriceComponent />}
			{priceKind === PriceKind.RushFee && <RushFeePriceComponent />}
		</Form>
	</ModalWrapper>
);
