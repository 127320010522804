import React from 'react';

import { Col, ColProps } from 'antd/lib/grid';

import { FormikField } from '@app/components/Forms/FormikField';

export interface CommonProps extends ColProps {
	disabled?: boolean;
	containerClassName?: string;
	placeholders?: {
		batchCount: string;
		batchPrice: string;
	}
}
// col-sm-4
export const BatchPriceFields: React.FC<CommonProps> = ({ disabled, placeholders, ...colProps }: CommonProps) => (
	<>
		<Col xs={24} sm={24} md={8} {...colProps}>
			<FormikField
				fieldName="batchCount"
				title="Batch count"
				inputProps={{
					type: 'number',
					min: 0,
					inputMode: 'decimal',
					disabled,
					placeholder: placeholders?.batchCount,
				}}
			/>
		</Col>
		<Col xs={24} sm={24} md={8} {...colProps}>
			<FormikField
				fieldName="batchPrice"
				title="Batch price"
				inputProps={{
					type: 'number',
					min: 0,
					inputMode: 'decimal',
					disabled,
					placeholder: placeholders?.batchPrice,
				}}
			/>
		</Col>
	</>
);
