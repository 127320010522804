import React from 'react';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import Input, { InputProps } from 'antd/lib/input';

type PasswordFieldProps = InputProps;

export const PasswordField: React.FC<PasswordFieldProps> = (props: PasswordFieldProps) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	return (
		<Input
			{...props}
			type={visible ? 'text' : 'password'}
			suffix={!visible
				? <EyeOutlined onClick={() => setVisible(true)} />
				: <EyeInvisibleOutlined onClick={() => setVisible(false)} />}
		/>
	);
};
