import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	CoreSelect,
	CoreSelectProps,
} from '@app/components/UI/Inputs/CoreSelect';
import { UserSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';
import { UserListInfo } from '@app/objects/User';

interface BaseProps {
	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	pickDefault?: (entity: Array<UserListInfo>) => number;
	onDeselect?: (id: number) => void;
	getDisabledOption?: (item: UserListInfo) => boolean;

	label?: string;
	placeholder?: string;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

function getName(item: UserListInfo) {
	if (!item.name || item.name.trim() === '') return 'No name user';

	return item.name;
}

export const UserSelect = (props: OwnProps):JSX.Element => {
	const [query, setQuery] = React.useState<string>('');

	const filters: Nullable<UserSelectFilter> = React.useMemo(() => ({
		name: query,
		search: query,
	}), [query]);

	const properties: CoreSelectProps<number, 'users', SelectRequestParams<number>> = {
		...props,
		onSearch: setQuery,
		filters,
		getName,
		shouldPickDefault,
		isEmptyValue,
		store: 'users',
	};

	return <CoreSelect {...properties} />;
};
