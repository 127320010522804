import { generatePath } from 'react-router-dom';

import { RoutePaths } from '@app/utilities/RoutePathVars';

type ClinicPathProps = Record<string, string | number | boolean | undefined>

export const getClinicPath = (props: ClinicPathProps): string => generatePath(RoutePaths.clinicEditor, props);

export const clinicDeleteEndpoint = 'deleteClinic';
export const clinicListEndpoint = 'clinicList';
export const saveClinicEndpoint = 'createClinic';
export const updateClinicEndpoint = 'updateClinic';
export const getClinicEndpoint = 'getClinic';
export const clinicListPath = generatePath(RoutePaths.clinicList, { tab: 'clinic-list' });
export const getNewClinicPath = (): string => getClinicPath({ id: '-1', type: 'edit', tab: 'general' });

export const clinicStoreName = 'clinic';
export const clinicsStoreName = 'clinics';

export const getClinicViewPath = (id: number, tab: string): string => getClinicPath({ id, tab, type: 'view' });
export const getClinicEditPath = (id: number, tab: string): string => getClinicPath({ id, tab, type: 'edit' });

export const locationsList = 'locationList';
export const createLocationEndpoint = 'createLocation';
export const updateLocationEndpoint = 'updateLocation';
export const deleteLocationEndpoint = 'deleteLocation';
