import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldProps } from 'formik';
import { Col, Row } from 'antd/lib/grid';

import { PostalCodeAutocomplete } from '@app/components/UI/Inputs/Address/PostalCodeAutocomplete';
import { PostalCode } from '@app/objects/PostalCode';
import { RegionSelect } from '@app/components/UI/Inputs/Address/RegionSelect';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { CardSize, Card, getCardFields } from '@app/components/UI/Cards/Card';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { BaseSectionProps, getDeliveryInfoFields } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { ApplicationState } from '@app/store';
import { addressService } from '@app/services/AddressService';
import { FormikField } from '@app/components/Forms/FormikField';

export const DeliveryDetails: React.FC<Partial<BaseSectionProps>> = ({ mode }: Partial<BaseSectionProps>) => {
	const { t } = useTranslation();
	const clinic = useSelector((state: ApplicationState) => state.clinic.item);
	const country = clinic?.country?.alpha3 ?? 'unknown';
	const details = addressService.provide(country);

	return (
		<Card
			name="Delivery Details"
			size={CardSize.Medium}
			mode={mode}
			fullWidth

			editContent={(
				<>
					<Row gutter={[30, 16]}>
						<Col xs={24} sm={24} md={12}>
							<FormikField fieldName="deliveryAddress" title={`${t(iKey('labels.delivery-address-1'))}*`} />
						</Col>
						<Col xs={24} sm={24} md={12}>
							<FormikField fieldName="deliveryAddress2" title={t(iKey('labels.delivery-address-2'))} />
						</Col>
					</Row>
					<Row gutter={[30, 16]}>
						<Col xs={24} sm={24} md={8}>
							<FormikField
								fieldName="deliveryZip"
								title={`${t(iKey(`labels.delivery-${details.mailKey}`))}*`}
								render={(fieldProps: FieldProps<string, FormValues>) => (
									<PostalCodeAutocomplete
										value={fieldProps.field.value}
										onChange={(value: string) =>
											fieldProps.form.setFieldValue(fieldProps.field.name as keyof FormValues, value, false)}
										onSelect={(value, item?: PostalCode) => {
											if (!item) return;

											fieldProps.form.setValues({
												...fieldProps.form.values,
												deliveryZip: item.zip,
												deliveryCity: item.city,
												deliveryStateId: item.stateId,
											});
										}}
										country={country}
									/>
								)}
							/>
						</Col>
						<Col xs={24} sm={24} md={8}>
							<FormikField
								fieldName="deliveryCity"
								title={`${t(iKey('labels.delivery-city'))}*`}
							/>
						</Col>
						<Col xs={24} sm={24} md={8}>
							<FormikField
								fieldName="deliveryStateId"
								title={`${t(iKey(`labels.delivery-${details.regionKey}`))}*`}
								render={({ field, form }) => (
									<RegionSelect
										value={field.value}
										onChange={(state?: number) => {
											form.setFieldValue(field.name, state);
											form.setFieldValue('deliveryState', null);
										}}

										country={country}
									/>
								)}
							/>
						</Col>
					</Row>
				</>
			)}
			viewContent={(
				<Field name="specialInstructions">
					{({ form }: FieldProps<FormValues>) => getCardFields(getDeliveryInfoFields(form.values, t, details))}
				</Field>
			)}
		/>
	);
};
