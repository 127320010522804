import * as React from 'react';

import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { RouteSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { Route } from '@app/objects/Route';

interface BaseSelectProps {
	pickDefault?: (entity: Array<Route>) => number;
	isDefault?: boolean;

	multiple?: boolean;
	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	onSelect?: (id: number) => void;
	onDeselect?: (value: number) => void;
	onBlur?: () => void;
	allowClear?: boolean;

	label?: string;
	placeholder?: string;
}

interface SingleSelectProps extends BaseSelectProps {
	value?: number;
	onChange: (value?: number) => void;

	multiple?: false;
}

interface MultipleSelectProps extends BaseSelectProps {
	value?: Array<number>;
	onChange: (value?: Array<number>) => void;

	multiple: true;
}

type RouteSelectProps = SingleSelectProps | MultipleSelectProps;

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value, isDefault = true): boolean {
	if (!isDefault) return false;

	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

export const RouteSelect: React.FC<RouteSelectProps> = (props: RouteSelectProps) => {
	const [query, setQuery] = React.useState<string>(() => '');

	const filters: RouteSelectFilter = React.useMemo(() => ({
		name: query,
		search: query,
	}), [query]);

	const properties = {
		...props,
		onSearch: setQuery,
		filters,
		shouldPickDefault: (store, multiple, isEmptyValue, reqProps, value) =>
			shouldPickDefault(store, multiple, isEmptyValue, reqProps, value, props.isDefault),
		isEmptyValue,
	};

	return <CoreSelect store="routes" {...properties} />;
};
