import React from 'react';
import { useTranslation } from 'react-i18next';

interface TotalQuantityProps {
	count?: number;
	withTotalQuantity?: boolean;
}

export const TotalQuantity: React.FC<TotalQuantityProps> = ({ count, withTotalQuantity }:TotalQuantityProps) => {
	const { t } = useTranslation('translation');

	if (!count || !withTotalQuantity) return null;

	return (
		<span className="filters-quantity">
			{count} {t('filters.quantity')}
		</span>
	);
};
