import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithId } from '@common/typescript/objects/WithId';
import { List } from '@common/typescript/objects/List';

export interface DashboardCounters {
	services: List<DashboardEntity>;
	statuses: List<DashboardEntity>;
	reports: List<DashboardEntity>;
}
export interface DashboardCountersList {
	services: Array<DashboardEntity>;
	statuses: Array<DashboardEntity>;
	reports: Array<DashboardEntity>;
}

export interface DashboardEntity extends WithId, WithDeleted {
	counter: number;
	entityId: number;
	order: number;
	title: string;
}

export enum DashboardCounterType {
	Status = 0,
	Service = 1,
	Report = 2,
}

export enum DashboardViewType {
	Cards = 0,
	Tables = 1
}

export enum ReportType {
	Pickup = 0,
	Dropoff = 1,
}
