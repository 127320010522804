import * as React from 'react';

import { List, transformArrayToList } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import { request } from '@common/react/components/Api';
import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/react/objects/BaseUser';

export type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
export interface Pagination {
	current: number;
	pageSize: number;
	total: number;
	onChange: (current: number) => void;
	hideOnSinglePage: boolean;
	position: Array<TablePaginationPosition>;
	showSizeChanger: boolean;
}

export interface TableData<T extends WithId> {
	pagination: Pagination;
	items: List<T>;
	isLoading: boolean;
	error: string | null;
	reload: (offset?: number) => void;
}

interface OwnProps<T extends WithId> {
	endpoint: string;
	reloadOnParamsChanged?: boolean;
	requestParams?: object;
	perPage?: number;
	children: (data: TableData<T>) => JSX.Element;
	onCompleted?: () => void;
}

const TableDataProvider = function<T extends WithId> (props: OwnProps<T>): JSX.Element {
	const {
		endpoint,
		reloadOnParamsChanged,
		requestParams,
		perPage = 10,
		onCompleted,
	} = props;

	const [isLoading, setLoading] = React.useState(false);
	const [currentPage, setPage] = React.useState(1);
	const [items, setItems] = React.useState<List<T>>(transformArrayToList([]));
	const [error, setError] = React.useState<string | null>(null);

	const prevPage = React.useRef<number>(0);

	const reload = (offset?: number) => {
		setLoading(true);
		setError(null);

		const promise = request<List<T>, BaseUser, BaseApplicationState<BaseUser>>(endpoint, {
			...requestParams,
			count: perPage,
			offset: offset ?? (currentPage - 1) * perPage,
		})
			.then((res: List<T>) => setItems(res))
			.catch(setError)
			.finally(() => setLoading(false));

		if (onCompleted) {
			promise.finally(onCompleted);
		}
	};

	React.useEffect(() => {
		if (prevPage.current !== currentPage) {
			reload();
		} else if (reloadOnParamsChanged) {
			setPage(1);

			if (currentPage === 1) {
				reload();
			}
		}

		prevPage.current = currentPage;
	}, [requestParams, currentPage]);

	const data: TableData<T> = {
		reload,
		pagination: {
			current: currentPage,
			pageSize: perPage,
			total: items.count,
			onChange: setPage,
			hideOnSinglePage: true,
			position: ['bottomCenter'],
			showSizeChanger: false,
		},
		items,
		error,
		isLoading,
	};

	return props.children(data);
};

export default TableDataProvider;
