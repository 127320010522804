import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CrematoryTables, SingleTableOptions } from '@app/components/UI/CrematoryTables/CrematoryTables';
import { PriceList } from '@app/components/Various/PriceList/PriceList';
import { PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import { ApplicationState } from '@app/store';
import { useCrematory } from '@app/hooks/useCrematory';

interface OwnProps {
	crematoryId: number;
}

export function getServiceType(serviceType: ServiceType): string {
	switch (serviceType) {
	case ServiceType.Private:
		return 'Private';
	case ServiceType.Communal:
		return 'Communal';
	case ServiceType.SemiPrivate:
		return 'SemiPrivate';
	case ServiceType.Storage:
		return 'Storage';

	default:
		return '';
	}
}

const getStoreName = (price: PriceType, service: ServiceType): keyof ApplicationState => {
	const priceName = price === PriceType.Wholesale ? 'Clinic' : 'Retail';
	const serviceName = getServiceType(service);

	return `crematoryPricesFor${priceName}${serviceName}` as keyof ApplicationState;
};

export const CrematoryPricesTab: React.FC<OwnProps> = ({ crematoryId }: OwnProps) => {
	const crematory = useCrematory();
	const history = useHistory();

	return (
		<CrematoryTables urlPrefix="price">
			{
				(data: SingleTableOptions) => (
					<PriceList
						data={data}
						crematoryId={crematoryId}
						clinicId={null}
						priceType={data.price}
						serviceType={data.service}

						weightUnit={crematory?.units.weight}
						storeName={getStoreName(data.price, data.service)}

						history={history}
						location={history.location}
					/>
				)
			}
		</CrematoryTables>
	);
};
