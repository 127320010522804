import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	CoreSelect,
	CoreSelectProps,
} from '@app/components/UI/Inputs/CoreSelect';
import { SpecialServicesSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';
import { ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';

interface BaseProps {
	crematoryId?: number;
	serviceType?: ServiceType;
	priceType?: PriceType;
	specialServiceType?: Array<SpecialServiceType>;
	availableForCrematory?: boolean;
	availableForClinic?: number;
	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	pickDefault?: (entity) => number;
	onDeselect?: (id: number) => void;
	onSelect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value?: number) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value?: Array<number>) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

interface RequestParams extends SelectRequestParams<number> {
	crematoryId: number;
	priceType: PriceType;
	serviceType: ServiceType;
	crematoryNotHave?: Nullable<number>;
	clinicNotHave?: Nullable<number>;
}

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(
	store,
	multiple,
	isEmptyValue,
	reqProps,
	value,
): boolean {
	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

export const SpecialServiceSelect: React.FC<OwnProps> = (props: OwnProps): JSX.Element => {
	const [query, setQuery] = React.useState<string>('');
	const filters: Nullable<SpecialServicesSelectFilter> = React.useMemo(() => ({
		name: query,
		search: query,
		specialServiceType: props.specialServiceType,
		priceType: props.priceType,
		serviceType: props.serviceType,
		availableForCrematory: props.availableForCrematory,
		availableForClinic: props.availableForClinic,
	}), [query, props.specialServiceType, props.priceType, props.serviceType, props.availableForCrematory, props.availableForClinic]);

	const reqParams = React.useMemo(() => ({
		crematoryId: props.crematoryId,
	}), [props.crematoryId]);

	const properties: CoreSelectProps<number, 'specialServices', RequestParams> = {
		...props,
		onSearch: setQuery,
		filters,
		reqParams,
		shouldPickDefault,
		isEmptyValue,
		store: 'specialServices',
	};

	return <CoreSelect {...properties} />;
};
