import React from 'react';

import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import { Field, FieldProps } from 'formik';

import { getLoadableScrollHandler } from '@common/react/components/Utils/Utils';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import ListDataProvider, { ListData } from '@common/react/smart components/List/ListDataProvider';

import { PetSpecie } from '@app/objects/Pet';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { WeightUnits } from '@app/objects/Crematory';
import { useCrematory } from '@app/hooks/useCrematory';

const { Option } = Select;

export const PriceComponent: React.FC = (): JSX.Element => {
	const [query, setQuery] = React.useState<string>('');
	const crematory = useCrematory();
	const unit = WeightUnits[crematory?.units.weight ?? 0].slice(0, -1).toLowerCase();

	const params = React.useMemo(() => ({
		filters: {
			search: query,
		},
	}), [query]);

	return (
		<Stack
			direction={StackDirection.Vertical}
			gap={2}
		>
			<Field name="petSpecieId">
				{(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title="Pet species*"
						containerClassName="col-sm-12"
						render={({ field, form }: FieldProps<FormValues>) => (
							<ListDataProvider endpoint="petSpecieList" requestParams={params} requestOnParamsChanged>
								{
									(data: ListData<PetSpecie>) => (
										<Select
											showSearch
											optionFilterProp="children"
											placeholder="Select a pet species"
											onPopupScroll={getLoadableScrollHandler(data.fetchMore, data.items.length < data.total)}
											value={field.value}
											onChange={(value) => form.setFieldValue(field.name, value)}
											disabled={!data.total}
											loading={data.isLoading}
											onSearch={(value) => setQuery(value)}
										>
											<Option disabled value={-2} key={-2}>Select a pet species</Option>
											<Option value={-1} key={-1}>All</Option>
											{
												data.items.map((item) => (
													<Option key={item.id} value={item.id} title={item.name}>{item.name}</Option>
												))
											}
										</Select>
									)
								}
							</ListDataProvider>
						)}
					/>
				)}
			</Field>

			<Stack direction={StackDirection.Horizontal} gap={0} className="stack-container">
				<Field name="from">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title="Min weight*"
							containerClassName="col-sm-6"
							render={(formik: FieldProps) => (
								<Input
									value={formik.field.value}
									onChange={(event) => formik.form.setFieldValue(formik.field.name, event.currentTarget.valueAsNumber)}

									min={0}
									type="number"
									inputMode="decimal"
								/>
							)}
						/>
					)}
				</Field>
				<Field name="to">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title="Max weight*"
							containerClassName="col-sm-6"
							render={(formik: FieldProps) => (
								<Input
									value={formik.field.value}
									onChange={(event) => formik.form.setFieldValue(formik.field.name, event.currentTarget.valueAsNumber)}

									min={0}
									type="number"
									inputMode="decimal"
								/>
							)}
						/>
					)}
				</Field>
			</Stack>

			<Stack direction={StackDirection.Horizontal} gap={0} className="stack-container">
				<Field name="value">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title="Price*"
							containerClassName="col-sm-6"
							render={(formik: FieldProps) => (
								<Input
									value={formik.field.value}
									onChange={(event) => formik.form.setFieldValue(formik.field.name, event.currentTarget.valueAsNumber)}

									min={0}
									type="number"
									inputMode="decimal"
								/>
							)}
						/>
					)}
				</Field>
				<Field name="extra">
					{(fieldProps: FieldProps<FormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={`Price per ${unit}`}
							containerClassName="col-sm-6"
							render={(formik: FieldProps) => (
								<Input
									value={formik.field.value}
									onChange={(event) => formik.form.setFieldValue(formik.field.name, event.currentTarget.valueAsNumber)}

									min={0}
									type="number"
									inputMode="decimal"
								/>
							)}
						/>
					)}
				</Field>
			</Stack>
		</Stack>
	);
};
