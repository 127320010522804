import * as React from 'react';

import { List } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import RequestComponent, { RequestData } from '@common/react/smart components/RequestComponent';

export interface ListData<T extends WithId> {
	total: number;
	items: Array<T>;
	isLoading: boolean;
	error: string | null;
	fetchMore: () => void;
	reset: () => void;
}

export interface ListDataProps<T extends WithId> {
	endpoint: string;

	requestParams?: object;
	perPage?: number;
	children: (data: ListData<T>) => JSX.Element;
	onCompleted?: () => void;
	requestOnParamsChanged?: boolean;
	override?: boolean; // Override current items or extend them with new ones
	shouldFetch?: boolean;
}

interface InnerProps<T extends WithId> extends RequestData<List<T>> {
	children: (data: ListData<T>) => JSX.Element;
	perPage: number;
	override?: boolean;
	shouldFetch?: boolean;
}

function ListDataProviderInner<T extends WithId>(props: InnerProps<T>): JSX.Element {
	const {
		item,
		request,
		perPage,
		isLoading,
		error,
		shouldFetch = true,
	} = props;

	const [items, setItems] = React.useState<Array<T>>([]);
	const [page, setPage] = React.useState(0);

	React.useEffect(() => {
		if (item && item.list && item.list.length) {
			if (props.override) {
				setItems(item.list);
			} else {
				const newItems = [...items, ...item.list];
				setItems(newItems.filter((item: T, index: number) => newItems.findIndex((i: T) => i.id === item.id) === index));
			}
		}
	}, [item]);

	React.useEffect(() => {
		if (page < 0) {
			setPage(0);
		} else if (shouldFetch) {
			request({
				offset: perPage * page,
			});
		}
	}, [page, shouldFetch]);

	const res = {
		total: item?.count ?? 0,
		items,
		isLoading,
		error,
		fetchMore: () => setPage(page + 1),
		reset: () => {
			setItems([]);
			setPage(-1);
		},
	};

	return props.children(res);
}

function ListDataProvider <T extends WithId>(props: ListDataProps<T>): JSX.Element {
	const {
		endpoint,
		requestParams,
		perPage = 10,
		onCompleted,
		requestOnParamsChanged = false,
	} = props;

	const params = React.useMemo(() => ({
		...requestParams,
		count: perPage,
	}), [requestParams, perPage]);

	return (
		<RequestComponent
			endpoint={endpoint}
			requestParams={params}
			onCompleted={onCompleted}
			requestOnParamsChanged={requestOnParamsChanged}
		>
			{
				(data: RequestData<List<T>>) => (
					<ListDataProviderInner
						perPage={perPage}
						override={props.override}
						shouldFetch={props.shouldFetch}
						{...data}
					>
						{props.children}
					</ListDataProviderInner>
				)
			}
		</RequestComponent>
	);
}

export default ListDataProvider;
