import React from 'react';

import { LabelPaperTypography } from '@app/objects/LabelPaper';

interface LabelSkeletonProps {
	font: LabelPaperTypography;
}

export const LabelSkeleton: React.FC<LabelSkeletonProps> = ({ font }: LabelSkeletonProps) => {
	return (
		<div className="paper__skeleton">
			<div className="paper__skeleton-logo" />

			<div className="paper__skeleton_block-head">
				<div className="paper__skeleton-title" style={{ height: font.titleFontSize }} />

				<div className="paper__skeleton-subtitle" style={{ height: font.headerFontSize }} />
			</div>

			<div className="paper__skeleton_blok-text">
				<div className="paper__skeleton-text" style={{ height: font.baseFontSize }} />
				<div className="paper__skeleton-text" style={{ height: font.baseFontSize }} />
			</div>
		</div>
	);
};
