import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useFormikContext } from 'formik';
import Button from 'antd/lib/button';
import { EditOutlined } from '@ant-design/icons';
import Space from 'antd/lib/space';

import { ViewMode } from '@app/components/Utils/Utils';

import { List } from '@app/components/UI/List/List';
import { Editable, toEditableMode } from '@app/smart components/Editable/Editable';

import '@app/scss/ui/blocks/header-affix.scss';

interface EditorHeaderProps {
	name: string | React.ReactElement;
	backPath?: string;
	editPath?: string;

	viewButtons?: React.ReactNode;
	editButtons?: React.ReactNode;
	extraButtons?: React.ReactNode; // buttons displayed in view & edit format
}

export const HeaderEditButtons: React.FC = () => {
	const history = useHistory();
	const { t } = useTranslation('translation');
	const { isSubmitting, handleSubmit } = useFormikContext();

	return (
		<Space className="site-header__buttons">
			<Button
				onClick={() => history.goBack()}
			>
				{t('buttons.cancel')}
			</Button>
			<Button
				type="primary"
				loading={isSubmitting}
				onClick={() => handleSubmit()}
			>
				{t('buttons.save')}
			</Button>
		</Space>
	);
};

type HeaderViewButtonsProps = Pick<EditorHeaderProps, 'editPath'>;
const HeaderViewButtons: React.FC<HeaderViewButtonsProps> = (props: HeaderViewButtonsProps) => {
	const history = useHistory();

	return (
		<EditOutlined
			onClick={() => history.push(props.editPath ?? '/')}
			className="icon-margin"
			style={{ fontSize: '17px' }}
		/>
	);
};

export const EditorHeader: React.FC<EditorHeaderProps> = (props: EditorHeaderProps): React.ReactElement => {
	const { type } = useParams<{ type: ViewMode }>();

	return (
		<Editable mode={toEditableMode(type)}>
			<List.Header className="header-affix">
				<List.Header.Title backPath={props.backPath}>
					{props.name}
					<Editable.View>{props.viewButtons ?? (<HeaderViewButtons editPath={props.editPath} />)}</Editable.View>
				</List.Header.Title>
				<List.Header.Actions>
					<Editable.Edit>
						{props.editButtons}
					</Editable.Edit>
					{props.extraButtons}
				</List.Header.Actions>
			</List.Header>
		</Editable>
	);
};

EditorHeader.defaultProps = {
	editButtons: <HeaderEditButtons />,
};
