import * as React from 'react';

import { SortingDirection } from '@common/react/components/Pages/ItemsPage';

import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { InventoryParentsSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';
import { formatFullName } from '@app/utilities/inventory';

interface BaseProps {
	category?: Array<ItemCategory>;

	disabled?: boolean;
	className?: string;
	selectClassName?: string;

	pickDefault?: (items: Array<InventoryItem>) => number;
	onDeselect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;

	style?: React.CSSProperties;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading && store.items?.length
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

interface Sorter<T> {
	column?: [{
		caption: keyof T,
		direction: SortingDirection,
	}];
}

type RequestParams = SelectRequestParams<number> & Sorter<InventoryItem>;

const sorter: Sorter<InventoryItem> = {
	column: [{
		caption: 'fullName',
		direction: SortingDirection.Ascending,
	}],
};

export const InventoryParentsSelect = (props: OwnProps) => {
	const [query, setQuery] = React.useState<string>('');

	const filters: InventoryParentsSelectFilter = React.useMemo(() => ({
		name: query,
		search: query,
		isParent: true,
		category: props.category,
	}), [query, props.category]);

	const properties = {
		...props,
		onSearch: setQuery,
		getName: formatFullName,
		filterOption: () => true,
		filters,
		shouldPickDefault,
		reqParams: sorter,
	};

	return (
		<CoreSelect<number, 'inventoryParents', RequestParams> store="inventoryParents" {...properties} />
	);
};
