import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldProps, useFormikContext } from 'formik';
import Select from 'antd/lib/select';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { Nullable } from '@common/typescript/objects/Nullable';

import {
	PetEngraving,
	ServiceType,
	updateEngraving as getUpdatedEngraving,
	needUpdateEngraving,
} from '@app/objects/Pet';
import { getServiceTypePreferenceKind, reduceName } from '@app/objects/Crematory';

import { NEW_PET_ID } from '@app/components/Pages/ClinicPetEditor/ClinicApi';
import { GenderSelect } from '@app/components/UI/Inputs/GenderSelect';
import { BreedSelect } from '@app/components/UI/Inputs/PetBreedSelect';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import { PetSpecieSelect } from '@app/components/UI/Inputs/PetSpecieSelect';
import { CardSize, getCardFields, Card } from '@app/components/UI/Cards/Card';
import { BaseSectionProps, getPetInfoFields } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { EngravingList } from '@app/components/Various/Pet/EngravingList';
import { Price, PriceType } from '@app/objects/Price';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { ApplicationState } from '@app/store';

const { Option } = Select;

interface NameInputProps {
	prices: Array<Price>;
	prefillEngraving?: boolean;
}

const NameInput: React.VFC<NameInputProps> = ({ prices, prefillEngraving }: NameInputProps) => {
	const { values, setFieldValue } = useFormikContext<FormValues>();
	const needUpdate = needUpdateEngraving(values.name, values.engraving, prices, values.serviceType, prefillEngraving);

	React.useEffect(() => {
		if (needUpdate && values.id < 0 && values.name) {
			setFieldValue('engraving', getUpdatedEngraving(values.name, values.engraving));
		}
	}, [prices, values.serviceType, values.name, values.id]);

	return (
		<Field name="name">
			{(fieldProps : FieldProps<string, FormValues>) => (
				<FormikInput
					fieldProps={{
						...fieldProps,
						field: {
							...fieldProps.field,
							onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
								fieldProps.field.onChange(e);
								const engraving = fieldProps.form.values.engraving;

								if (needUpdate) {
									fieldProps.form.setFieldValue('engraving', getUpdatedEngraving(e.target.value, engraving));
								}
							},
						},
					}}
					title="Name*"
					containerClassName=""
				/>
			)}
		</Field>
	);
};

export const PetDetails: React.VFC<Omit<BaseSectionProps, 'crematoryId'>> = ({ crematory, mode }: Omit<BaseSectionProps, 'crematoryId'>) => {
	const { t } = useTranslation('translation', { keyPrefix: 'editors.clinic-pet-editor.cards.pet-info' });
	const [availableEngravingPrices, setAvailableEngravingPrices] = React.useState<Array<Price>>([]);
	const clinicId = useSelector((state: ApplicationState) => state.login.user?.clinicId);

	return (
		<Card
			name="Pet information"
			mode={mode}
			size={CardSize.Medium}

			editContent={(
				<>
					<NameInput prices={availableEngravingPrices} prefillEngraving={crematory?.prefillEngraving} />
					<Field name="genderId">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title="Gender*"
								containerClassName=""
								render={({ field, form }: FieldProps<string | number, FormValues>) => (
									<GenderSelect
										handleChange={(name, value) => form.setFieldValue(name as keyof FormValues, value, false)}
										value={field.value}
									/>
								)}
							/>
						)}
					</Field>
					<Field name="reportedWeight">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={`${crematory?.units && crematory?.units?.weight !== undefined
									? `Weight–${reduceName(crematory?.units.weight)}` : 'Weight'}*`}
								containerClassName=""
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} min={0} />
								)}
							/>
						)}
					</Field>
					<Field name="petSpecieId">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title="Species*"
								containerClassName=""
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<PetSpecieSelect
										value={field.value}
										onChange={(value: number) => form.setFieldValue(field.name as keyof FormValues, value, false)}
									/>
								)}
							/>
						)}
					</Field>
					<Field name="petBreedId">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title="Breed*"
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<BreedSelect
										petSpecieId={form.values.petSpecieId}
										onChange={(id: number | undefined) => {
											form.setFieldValue('petBreedId', id, false);
										}}
										value={field.value >= 0 ? field.value : undefined}
									/>
								)}
							/>
						)}
					</Field>
					<Field name="isMixed">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title="Breed type"
								render={({ field, form }) => (
									<Select
										optionFilterProp="children"
										value={field.value ? 1 : NEW_PET_ID}
										onChange={(value) => form.setFieldValue(field.name, value, false)}
									>
										<Option disabled value={-2} key={-2}>Select pet breed type</Option>
										<Option value={NEW_PET_ID} key={NEW_PET_ID}>Pure</Option>
										<Option value={1} key={1}>Mixed</Option>

									</Select>
								)}
							/>
						)}
					</Field>

					<Field name="color">
						{(fieldProps: FieldProps<FormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t('color')}
								containerClassName=""
							/>
						)}
					</Field>
					<Field name="internalIdNum">
						{(fieldProps: FieldProps<FormValues>) => {
							const serviceTypePreference = getServiceTypePreferenceKind(fieldProps.form.values.serviceType);
							if (!crematory?.[serviceTypePreference]?.showInternalId) return null;

							return (
								<FormikInput
									fieldProps={fieldProps}
									title={<ControlLabel text="Tag Id" required={crematory?.[serviceTypePreference]?.requireInternalId} />}
									containerClassName=""
								/>
							);
						}}
					</Field>

					<Field name="engraving">
						{(fieldProps: FieldProps<Nullable<Array<PetEngraving>>, FormValues>) => {
							if (fieldProps.form.values.serviceType === undefined) return null;
							if (fieldProps.form.values.serviceType === ServiceType.Communal) return null;

							return (
								<div style={{ gridColumnStart: 1 }}>
									<EngravingList
										value={fieldProps.field.value || []}
										onChange={(value: Array<PetEngraving>) =>
											fieldProps.form.setFieldValue(fieldProps.field.name, value, false)}
										onChangePrice={setAvailableEngravingPrices}

										clinicId={clinicId ?? undefined}
										serviceType={fieldProps.form.values.serviceType}
										priceType={PriceType.Wholesale}
										prefillEngraving={crematory?.prefillEngraving}

										ignoreClinic
									/>
								</div>
							);
						}}
					</Field>
				</>
			)}

			viewContent={(
				<Field name="">
					{({ form }: FieldProps<FormValues>) =>
						getCardFields(getPetInfoFields(form.values, t, crematory?.[getServiceTypePreferenceKind(form.values.serviceType)]?.showInternalId))}
				</Field>
			)}
		/>
	);
};
