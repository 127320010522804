import * as React from 'react';

import { ViewMode } from '@app/components/Utils/Utils';

export enum EditableMode {
	View = 0,
	Edit = 1,
}

interface WithChildren {
	children: React.ReactNode;
}

interface EditableProps extends WithChildren {
	mode?: EditableMode;
}

type EditableComponent = React.FC<EditableProps> & {
	View: React.FC<WithChildren>;
	Edit: React.FC<WithChildren>;
};

interface EditableContext {
	mode: EditableMode;
}

const Context = React.createContext<EditableContext>({ mode: EditableMode.View });

export function toEditableMode(mode: ViewMode): EditableMode {
	if (mode === ViewMode.edit) return EditableMode.Edit;

	return EditableMode.View;
}

export const Editable: EditableComponent = (props: EditableProps) => {
	const ctx: EditableContext = React.useMemo(() => ({ mode: props.mode! }), [props.mode]);

	return (
		<Context.Provider value={ctx}>
			{props.children}
		</Context.Provider>
	);
};

Editable.View = (props: WithChildren) => {
	const ctx: EditableContext = React.useContext(Context);

	if (ctx.mode !== EditableMode.View) return null;

	return <>{props.children}</>;
};

Editable.Edit = (props: WithChildren) => {
	const ctx: EditableContext = React.useContext(Context);

	if (ctx.mode !== EditableMode.Edit) return null;

	return <>{props.children}</>;
};

Editable.defaultProps = {
	mode: EditableMode.View,
};
