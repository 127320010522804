import React from 'react';

import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { DeactivateDropdownButton } from '@app/components/UI/Buttons/DeactivateDropdownButton';
import { TransmutationButton } from '@app/components/UI/Buttons/TransmutationButton';
import { User } from '@app/objects/User';
import { EditButton } from '@app/components/UI/Buttons/ActionButtons';
import { ResendEmailButton } from '@app/components/UI/Buttons/ResendEmailButton';

export interface ItemActionMenu {
	key: string;
	label: Nullable<React.ReactElement>;
}

export class ActionMenuBuilder {
	private template: Array<ItemActionMenu> = Array<ItemActionMenu>();

	public build(): Array<ItemActionMenu> {
		const result = this.template;
		this.template = Array<ItemActionMenu>();

		return result;
	}

	public addEdit(link: string, display: boolean = true): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'edit',
				label: (<EditButton linkPath={link} />),
			});
		}

		return this;
	}

	public addTransmutationButton(
		record: User,
		onSuccess?: () => void,
		display: boolean = true,
	): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'transmutation',
				label: (
					<TransmutationButton
						user={record}
						title="Login as"
						onSuccess={onSuccess}
					/>
				),
			});
		}

		return this;
	}

	public addEmailButton<T extends WithId & { email: string}>(
		record: T,
		display: boolean = true,
		allowResend: boolean = true,
		minified: boolean = true,
	): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'email',
				label: (<ResendEmailButton userId={record.id} email={record.email} allowResend={allowResend} minified={minified} />),
			});
		}

		return this;
	}

	public addDeleteButton(id: number, onDelete: () => void, display: boolean = true): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: 'delete',
				label: (
					<DeactivateDropdownButton
						userId={id}
						onDeactivated={onDelete}
					/>
				),
			});
		}

		return this;
	}

	public addButton(button: React.ReactElement | null, display: boolean = true, key?: string): ActionMenuBuilder {
		if (display) {
			this.template.push({
				key: key ?? 'update',
				label: button,
			});
		}

		return this;
	}
}
