import * as React from 'react';

import {
	Formik,
	FormikHelpers,
	FormikProps,
} from 'formik';

import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import { getShallowChanges } from '@common/typescript/utils/entity';

import { request } from '@app/components/Api';
import { ServiceType, DeliveryType } from '@app/objects/Pet';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { getValidationSchema } from '@app/components/Various/PriceEditor/schemas';
import { InnerForm } from '@app/components/Various/PriceEditor/PriceEditorComponents/InnerForm';
import {
	getPriceKindName,
	Price,
	PriceKind,
	PriceType,
	getDefaultPrice,
	DiscountType,
} from '@app/objects/Price';
import {
	CreatePriceMessage,
	toCreateMessage,
	UpdatePriceMessage,
	toUpdateMessage,
	noUrnId,
} from '@app/components/Various/PriceEditor/services';
import { InventoryItem } from '@app/objects/Inventory';

interface OwnProps {
	item: Nullable<Price>;
	priceId: Nullable<number | string>;

	crematoryId: number;
	clinicId: Nullable<number>;
	priceType: PriceType;
	serviceType: ServiceType;
	priceKind: PriceKind;

	onSave: () => void;
	closeDialog: () => void;
	visible: boolean;
	modalItemId: number | string;

	needRefresh: boolean;
	setNeedRefresh: (value: boolean) => void;
}

export interface FormValues extends WithId {
	name: string;
	description: string;
	unit: Nullable<DiscountType>;
	applyTo?: Nullable<PriceKind>;

	crematoryId: number;
	clinicId: Nullable<number>;

	priceKind: PriceKind;
	priceType: PriceType;
	serviceType: ServiceType;

	petSpecieId: Nullable<number>;
	specialServiceId: Nullable<Array<number> | number>;
	pickupServiceId: Nullable<Array<number> | number>;
	deliveryType: Nullable<DeliveryType>;
	inventoryItem: Nullable<InventoryItem>;
	inventoryItemId: Nullable<Array<Nullable<number>> | number>;

	from: number;
	to: number;
	value: number;
	extra: number;

	isDefault: boolean;

	batchPrice: number;
	batchCount: number;

	order?: Nullable<number>;
	maxSize?: Nullable<number>;

	selectAll: boolean;
	filters: {
		name: string;
		ancestorId: Array<number>;
	};
}

interface InitialProps {
	crematoryId: number;
	clinicId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;
	priceKind: PriceKind;
}

const getInitialValues = (
	item: Nullable<Price>,
	props: InitialProps,
): FormValues => {
	let value = item;

	if (value === null) {
		value = getDefaultPrice(props.crematoryId);
		value.clinicId = props.clinicId;
		value.priceType = props.priceType;
		value.serviceType = props.serviceType;
		value.priceKind = props.priceKind;
	}

	if (value.priceKind === PriceKind.BasePrice && value.petSpecieId === null) {
		value.petSpecieId = -1;
	}

	return {
		id: value.id,

		name: value.name,
		description: value.description,
		unit: value.unit,
		applyTo: value.applyTo ?? null,

		serviceType: value.serviceType,
		priceType: value.priceType,
		priceKind: value.priceKind,

		crematoryId: value.crematoryId,
		clinicId: value.clinicId,

		deliveryType: value.deliveryType,
		inventoryItem: value.inventoryItem,
		inventoryItemId: value.id > 0 ? value.inventoryItemId ?? noUrnId : [],
		petSpecieId: value.petSpecieId,
		specialServiceId: value.specialServiceId,
		pickupServiceId: value.pickupServiceId,

		from: value.from,
		to: value.to,
		extra: value.extra,
		value: value.value,

		batchPrice: value.batchPrice,
		batchCount: value.batchCount,
		isDefault: value.isDefault,

		order: value.order,
		maxSize: value.maxSize,

		selectAll: false,
		filters: {
			name: '',
			ancestorId: [],
		},
	};
};

const handleCreate = (item: FormValues): Promise<void> => {
	const message: Partial<CreatePriceMessage> = toCreateMessage(item);

	return request('createPrice', message)
		.then(() => {
			alertMessage(MessageType.success, `A new ${getPriceKindName(item.priceKind)} was added`);
		});
};

const handleUpdate = (item: FormValues, initial: FormValues): Promise<void> => {
	const message: Partial<UpdatePriceMessage> & WithId = getShallowChanges(toUpdateMessage(initial), toUpdateMessage(item));

	return request('updatePrice', message)
		.then(() => {
			alertMessage(MessageType.success, `${getPriceKindName(initial.priceKind)} was updated`);
		});
};

const handleSubmit = (
	values: FormValues,
	initial: FormValues,
	actions: FormikHelpers<FormValues>,
	closeDialog: () => void,
	onSave: () => void,
	setNeedRefresh: (value: boolean) => void,
) => {
	const item = values;

	if (values.petSpecieId && +values.petSpecieId === -1) {
		item.petSpecieId = null;
	}

	const task = values.id > 0 ? handleUpdate(item, initial) : handleCreate(item);

	return task
		.then(() => {
			actions.resetForm();
			closeDialog();
			onSave();
			setNeedRefresh(true);
		})
		.catch((error: string) => alertMessage(MessageType.error, `Error! ${error}`))
		.finally(() => actions.setSubmitting(false));
};

export const PriceEditor: React.FC<OwnProps> = 	({
	item,
	modalItemId,
	visible,
	serviceType,
	priceType,
	clinicId,
	crematoryId,
	priceId,
	closeDialog,
	onSave,
	priceKind,
	needRefresh,
	setNeedRefresh,
}) => {
	const initialProps: InitialProps = {
		crematoryId,
		clinicId,
		priceType,
		serviceType,
		priceKind,
	};
	const initialValues = getInitialValues(item, initialProps);
	const submit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
		handleSubmit(values, initialValues, actions, closeDialog, onSave, setNeedRefresh);

	if (item || modalItemId === -1) {
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={submit}
				enableReinitialize
				validationSchema={getValidationSchema(priceKind)}
			>
				{(formikBag: FormikProps<FormValues>) => (
					<InnerForm
						modalItemId={modalItemId}
						visible={visible}
						formikBag={formikBag}
						closeDialog={closeDialog}
						item={item}
						serviceType={serviceType}
						priceType={priceType}
						clinicId={clinicId}
						crematoryId={crematoryId}
						priceId={priceId}
						priceKind={priceKind}
						needRefresh={needRefresh}
						setNeedRefresh={setNeedRefresh}
					/>
				)}
			</Formik>
		);
	}

	if (visible) return <Loading />;

	return null;
};
