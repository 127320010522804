import * as Yup from 'yup';

import { Nullable } from '@common/typescript/objects/Nullable';
import { positiveNumberValidator } from '@common/react/utils/validationHelpers';

import { DiscountType, PriceKind } from '@app/objects/Price';
import { noUrnId } from '@app/components/Various/PriceEditor/services';

const valueMessage = 'Enter the price value';
const valueSchema = Yup.number().typeError(valueMessage).min(0, 'The price should not be negative');

export const hasNoItemIdInArray = (inventoryItemId?: Nullable<number> | Array<Nullable<number>>) => {
	return Array.isArray(inventoryItemId) && inventoryItemId.length === 1 && inventoryItemId?.[0] === noUrnId;
};

export const validationSchemaPrice = Yup.object().shape({
	value: Yup.number().when(['priceKind', 'inventoryItemId'], {
		is: (priceKind: PriceKind, inventoryItemId?: Array<Nullable<number>> | number) => (
			priceKind === PriceKind.UrnPrice || priceKind === PriceKind.ProductPrice)
			&& (inventoryItemId === noUrnId || hasNoItemIdInArray(inventoryItemId)),
		then: () => Yup.number().typeError(valueMessage),
		otherwise: () => valueSchema,
	}),
	from: Yup.number().typeError('Enter the min weight').min(0, 'Min weight should not be negative').required('Min weight is required'),
	to: Yup.number().typeError('Enter the max weight').min(0, 'Max weight should not be negative').required('Max weight is required'),
});

export const validationSchemaEngraving = Yup.object().shape({
	value: valueSchema,
	maxSize: Yup.number().typeError('Enter the max size').min(0, 'Negative line length is prohibited'),
});

export const validationSchemaSpecialService = Yup.object().shape({
	value: valueSchema,
	specialServiceId: Yup.mixed().when('id', {
		is: (id: number) => id > 0,
		then: () => positiveNumberValidator,
		otherwise: () => Yup.array().when('selectAll', {
			is: (val: boolean) => val,
			then: () => Yup.array(),
			otherwise: () => Yup.array().min(1, 'Select the Special Service').required('Select the Special Service'),
		}),
	}),
});

export const validationSchemaDiscount = Yup.object().shape({
	name: Yup.string().required('The Name is required field'),
	unit: Yup.number().oneOf([DiscountType.Value, DiscountType.Percentage]),
	value: Yup.number()
		.when(['unit'], {
			is: (unit: DiscountType) => unit === DiscountType.Percentage,
			then: () => Yup.number().typeError(valueMessage).min(0).max(100),
			otherwise: () => Yup.number().typeError(valueMessage),
		})
		.required('The Unit is required field'),
	applyTo: Yup.number()
		.nullable()
		.oneOf([
			PriceKind.BasePrice,
			PriceKind.SpecialServicePrice,
			PriceKind.UrnPrice,
			PriceKind.ProductPrice,
			null,
		], 'Discounts are only applied to Cremation, Services and Products'),
});

export const validationSchemaRushFee = Yup.object().shape({
	value: valueSchema,
});

export function getValidationSchema(priceKind: PriceKind) {
	switch (priceKind) {
	case PriceKind.SpecialServicePrice:
		return validationSchemaSpecialService;

	case PriceKind.Discount:
		return validationSchemaDiscount;

	case PriceKind.Engraving:
		return validationSchemaEngraving;

	case PriceKind.RushFee:
		return validationSchemaRushFee;

	default:
		return validationSchemaPrice;
	}
}
