import React from 'react';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import { Nullable } from '@common/typescript/objects/Nullable';

interface LoaderProps {
	loading: boolean;
}

const loaderDebounce = 500;
export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	const [visible, setVisible] = React.useState<boolean>(false);
	const timer = React.useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

	const clear = () => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}
	};

	React.useEffect(() => clear, []);
	React.useEffect(() => {
		if (props.loading) {
			setVisible(true);
			clear();
		} else {
			timer.current = setTimeout(() => setVisible(false), loaderDebounce);
		}
	}, [props.loading]);

	if (!visible) return null;

	return (
		<div className="loading-container__overlay">
			<div className="loading-container__relative">
				<Loading className="" />
			</div>
		</div>
	);
};
