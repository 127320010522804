import React from 'react';

import { Col, Row } from 'antd/lib/grid';

import { FormikField } from '@app/components/Forms/FormikField';

export const RushFeePriceComponent: React.FC = () => (
	<Row>
		<Col xs={24} sm={12}>
			<FormikField
				fieldName="value"
				title="Value*"
				inputProps={{
					type: 'number',
					min: 0,
					inputMode: 'decimal',
				}}
			/>
		</Col>
	</Row>
);
