import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, Row } from 'antd/lib/grid';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Field, FieldProps, useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ApplicationState } from '@app/store';
import { ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { SpecialService, SpecialServiceType } from '@app/objects/SpecialService';
import { SpecialServiceSelect } from '@app/components/UI/Inputs/SpecialServiceSelect';
import {
	BatchPriceFields,
	CommonProps,
} from '@app/components/Various/PriceEditor/PriceEditorComponents/BatchPriceFields';
import {
	isEqualSpecialServices,
	isPickDefault,
	SpecialServicesSelectFilter,
} from '@app/store/SelectList/SelectsInterfaces';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { ModalTable } from '@app/components/Various/PriceEditor/PriceEditorComponents/ServicePrice/ModalTable';
import { FormikField } from '@app/components/Forms/FormikField';

interface SpecialServiceProps {
	serviceType: ServiceType;
	priceType: PriceType;

	clinicId: Nullable<number>;
	crematoryId: number;

	priceId: Nullable<number | string>;

	isModalOpen: boolean;
	needRefresh: boolean;
	setNeedRefresh: (value: boolean) => void;
}

const IsDefaultControl: React.FC<CommonProps> = (props: CommonProps) => (
	<FormikField
		fieldName="isDefault"
		title="Default"
		render={({ field, form }: FieldProps<boolean, FormValues>) => (
			<div className="is-relative">
				<Checkbox
					checked={field.value}
					onChange={(value: CheckboxChangeEvent) =>
						form.setFieldValue(field.name, value.target.checked, false)}
					disabled={props.disabled}
				>
					Service is selected by default
				</Checkbox>
			</div>
		)}
	/>
);

export const SpecialServiceComponent: React.FC<SpecialServiceProps> = 	({
	serviceType, priceType, clinicId, crematoryId, priceId, isModalOpen, needRefresh, setNeedRefresh,
}): JSX.Element => {
	const { values } = useFormikContext<FormValues>();
	const dispatch = useDispatch();
	const store = useSelector((state: ApplicationState) => state.selects.specialServices.items);
	const specialService = store.find((item) => item.id === values.specialServiceId);
	const reqSelect = React.useMemo(() =>
		bindActionCreators(getActionCreators(
			'specialServices',
			{ endpoint: 'specialServiceList', equal: isEqualSpecialServices },
		), dispatch), [dispatch]);

	const filters: Nullable<SpecialServicesSelectFilter> = React.useMemo(() => ({
		search: '',
		specialServiceType: [SpecialServiceType.Service, SpecialServiceType.Kit],
	}), []);

	const reqParams = React.useMemo(() => ({
		crematoryId,
		filters: {
			priceType,
			serviceType,
			availableForCrematory: priceId && !clinicId ? true : undefined,
			availableForClinic: priceId && clinicId ? clinicId : undefined,
		},
	}), [crematoryId, priceType, serviceType, priceId, clinicId]);

	const paramsForTable = React.useMemo(() => ({
		...reqParams,
		filters: {
			...reqParams.filters,
			specialServiceType: [SpecialServiceType.Service, SpecialServiceType.Kit],
		},
	}), [reqParams]);

	React.useEffect(() => {
		if (!isModalOpen) return;

		if (needRefresh) {
			reqSelect.refresh(filters, reqParams);
			setNeedRefresh(false);
		}

		if (values.id > 0 && store.length && !specialService && values.specialServiceId) {
			reqSelect.refresh(filters, {
				preselect: [values.specialServiceId],
				...reqParams,
			});
		}
	}, [values.id, values.specialServiceId, store, specialService, needRefresh, isModalOpen]);

	return (
		<Stack direction={StackDirection.Vertical} gap={4}>
			<Stack direction={StackDirection.Vertical} gap={2}>
				<Row gutter={[30, 16]}>
					<BatchPriceFields />
					<Col xs={24} sm={24} md={8}>
						<FormikField
							fieldName="value"
							title="Value*"
							inputProps={{
								type: 'number',
								min: 0,
								inputMode: 'decimal',
							}}
						/>
					</Col>
				</Row>
				<IsDefaultControl />
			</Stack>

			<Field name="specialServiceId">
				{(fieldProps: FieldProps<FormValues>) => {
					if (priceId && Number(priceId) > 0) {
						return (
							<FormikInput
								fieldProps={fieldProps}
								title="Special service*"
								containerClassName="col-sm-6"
								render={({ field, form }) => (
									<SpecialServiceSelect
										value={field.value}
										onChange={(id?: number) => form.setFieldValue(field.name as keyof FormValues, id, false)}
										placeholder="Select a special service"
										pickDefault={(store: Array<SpecialService>) => isPickDefault(store)}
										specialServiceType={[SpecialServiceType.Service, SpecialServiceType.Kit]}
										priceType={priceType}
										serviceType={serviceType}
										availableForCrematory={!clinicId ? true : undefined}
										availableForClinic={clinicId ?? undefined}
										crematoryId={crematoryId}
										allowClear={false}
									/>
								)}
							/>
						);
					}

					return (
						<ModalTable
							endpoint="specialServiceList"
							fieldName="specialServiceId"
							additionalRequestParams={paramsForTable}
						/>
					);
				}}
			</Field>
		</Stack>
	);
};
