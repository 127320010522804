import React from 'react';
import { Col, Row } from 'antd/lib/grid';

import { FormikField } from '@app/components/Forms/FormikField';

export const EngravingPriceComponent: React.FC = () => (
	<Row gutter={[30, 16]}>
		<Col xs={24} sm={24} md={12}>
			<FormikField
				fieldName="value"
				title="Value*"
				inputProps={{
					type: 'number',
					min: 0,
					inputMode: 'decimal',
				}}
			/>
		</Col>
		<Col xs={24} sm={24} md={12}>
			<FormikField
				fieldName="maxSize"
				title="Maximal line length"
				inputProps={{
					type: 'number',
					min: 0,
					inputMode: 'decimal',
				}}
			/>
		</Col>
	</Row>
);
