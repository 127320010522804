import * as React from 'react';

import {
	CoreSelect,
	CoreSelectProps,
} from '@app/components/UI/Inputs/CoreSelect';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { InventoryItemFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';

interface BaseProps {
	crematoryId: number;
	category: Array<ItemCategory>;

	disabled?: boolean;
	className?: string;
	activeClassName?: string;
	selectClassName?: string;

	pickDefault?: (items: Array<InventoryItem>) => number;
	onDeselect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;

	style?: React.CSSProperties;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

interface RequestParams extends SelectRequestParams<number> {
	withParent: boolean;
}

function getName(item: InventoryItem) {
	const name = item.parent ? `${item.parent.name} (${item.name})` : item.name;

	return item.fullName ?? name;
}

const reqParams: Partial<RequestParams> = {
	withParent: true,
};

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading && store.items?.length
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

export const InventoryItemSelect = (props: OwnProps) => {
	const [query, setQuery] = React.useState<string>('');

	const filters: InventoryItemFilter = React.useMemo(() => ({
		name: query,
		search: query,
		crematoryId: props.crematoryId,
		category: props.category,
	}), [query, props.crematoryId, props.category]);

	const properties: CoreSelectProps<number, 'inventoryItems', RequestParams> = {
		...props,
		onSearch: setQuery,
		reqParams,
		getName,
		filters,
		shouldPickDefault,
		store: 'inventoryItems',
	};

	return (
		<CoreSelect {...properties} />
	);
};
