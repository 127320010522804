import * as React from 'react';

import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { LanguageSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { Language } from '@app/objects/Language';

interface BaseProps {
	disabled?: boolean;
	className?: string;
	selectClassName?: string;

	pickDefault?: (entity: Array<Language>) => number;
	onDeselect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

export const LanguageSelect = (props: OwnProps): JSX.Element => {
	const [query, setQuery] = React.useState<string>('');

	const filters: LanguageSelectFilter = React.useMemo(() => ({
		search: query,
	}), [query]);

	const properties = {
		...props,
		onSearch: setQuery,
		filters,
		shouldPickDefault,
		isEmptyValue,
	};

	return <CoreSelect store="languages" {...properties} />;
};
