import * as Yup from 'yup';

import {
	positiveNumberValidator,
	simpleStringValidator,
} from '@common/react/utils/validationHelpers';

import { stringValidator } from '@app/utilities/validators';
import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { NEW_PET_ID, validationMessage } from '@app/components/Pages/ClinicPetEditor/ClinicApi';
import { CrematoryListInfo, getServiceTypePreferenceKind, useCrematoryServiceType } from '@app/objects/Crematory';
import { allowAdd } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';

const getValuesOfServiceType = () => {
	const values = new Set();
	const {
		useSemiPrivate, useCommunal, usePrivate, useStorage,
	} = useCrematoryServiceType();

	if (useCommunal) values.add(ServiceType.Communal);
	if (usePrivate) values.add(ServiceType.Private);
	if (useSemiPrivate) values.add(ServiceType.SemiPrivate);
	if (useStorage) values.add(ServiceType.Storage);

	const types = [...values] as Array<ServiceType>;

	return {
		values: types,
		names: types.map((i) => ServiceType[i]).join(', '),
	};
};

const isMailDelivery = (deliveryType: DeliveryType) => deliveryType === DeliveryType.Mail;
const isHomeDropOffDelivery = (deliveryType: DeliveryType) => deliveryType === DeliveryType.HomeDropOff;

export const getValidationSchema = (crematory?: CrematoryListInfo) => Yup.object().shape({
	ownerLastName: simpleStringValidator,
	name: simpleStringValidator,
	petSpecieId: positiveNumberValidator,
	petBreedId: positiveNumberValidator,
	genderId: Yup.number().moreThan(NEW_PET_ID, validationMessage).required(validationMessage),
	reportedWeight: positiveNumberValidator,
	serviceType: Yup
		.number()
		.oneOf(getValuesOfServiceType().values, `Service Type must be one of the following values: ${getValuesOfServiceType().names}`)
		.required(validationMessage),
	discountId: Yup.number().positive('Must be positive').nullable(),
	noAshes: Yup.boolean()
		.when('serviceType', {
			is: (serviceType) => ServiceType.Communal === +serviceType,
			then: () => Yup.boolean().oneOf([true], 'Required field'),
		}),
	services: Yup.array().of(
		Yup.object().shape({
			count: Yup.number()
				.required('Quantity is required filed')
				.min(0, 'Quantity should be a positive value')
				.integer('Quantity quantity should be an integer value'),
		}),
	),
	urns: Yup.array().when('serviceType', {
		is: (val) => val !== ServiceType.Communal,
		then: () => Yup.array().of(
			Yup.object().shape({
				count: Yup.number().when('done', {
					is: (val) => val === false,
					then: () => Yup.number().min(1, 'Count should be a positive value'),
				})
					.required('Count should be a positive value')
					.integer('Count quantity should be an integer value'),
				id: Yup.number().when(['entry', 'nodeId'], {
					is: (entry, nodeId) => !allowAdd(entry, nodeId),
					then: () => Yup.number().max(-3, 'Not all parameters for the urn are selected'),
				}),
			}),
		),
	}),
	deliveryAddress: Yup.string().when('deliveryType', {
		is: (type) => isMailDelivery(type) || isHomeDropOffDelivery(type),
		then: () => stringValidator('Delivery address'),
		otherwise: () => Yup.string(),
	}),
	deliveryCity: Yup.string().when('deliveryType', {
		is: (type) => isMailDelivery(type) || isHomeDropOffDelivery(type),
		then: () => stringValidator('Delivery city'),
		otherwise: () => Yup.string(),
	}),
	deliveryStateId: Yup.string().when('deliveryType', {
		is: (type) => isMailDelivery(type) || isHomeDropOffDelivery(type),
		then: () => Yup.number().min(1, 'Delivery State is required').required('Delivery State is required'),
		otherwise: () => Yup.number().nullable(),
	}),
	deliveryZip: Yup.string().when('deliveryType', {
		is: (type) => isMailDelivery(type) || isHomeDropOffDelivery(type),
		then: () => stringValidator('Delivery zip'),
		otherwise: () => Yup.string(),
	}),
	internalIdNum: Yup.string().when('serviceType', {
		is: (type) => crematory?.[getServiceTypePreferenceKind(type)]?.requireInternalId,
		then: () => stringValidator('Tag Id'),
	}),
});
