export interface ImportSpeciesDataMessage {
	species: Array<ImportSpeciesData>;
}

interface ImportSpeciesData {
	name: string;
	breeds: Array<string>;
}

export interface ImportStatusesDataMessage {
	statuses: Array<string>;
}

export interface ImportSpecialServicesDataMessage {
	services: Array<string>;
}

export function readFile<T>(file: File): Promise<T> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsText(file, 'utf-8');

		reader.onload = () => {
			const str = reader.result as string;

			try {
				const json = JSON.parse(str);
				resolve(json as T);
			} catch (e) {
				reject(e);
			}
		};
		reader.onerror = () => {
			const error = reader.error ? `${reader.error.name}: ${reader.error.message}` : 'Failed to parse file';
			reject(new Error(error));
		};
	});
}
