import React from 'react';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd/lib/grid';

import { isPresent } from '@common/typescript/objects/Nullable';
import { getShallowChanges } from '@common/typescript/utils/entity';

import { Price, PriceKind } from '@app/objects/Price';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { BatchPriceFields } from '@app/components/Various/PriceEditor/PriceEditorComponents/BatchPriceFields';
import { WeightUnits } from '@app/objects/Crematory';
import { useCrematory } from '@app/hooks/useCrematory';
import { request } from '@app/components/Api';
import { MessageType, alertMessage } from '@app/utilities/alert';
import { FormikField } from '@app/components/Forms/FormikField';

interface MultipleEditProps {
	items: Array<Price>;
	priceKind: PriceKind;
	modalItemId: number | string;
	rowSelection: {
    onChange: (selectedRows: Array<number>) => void;
    selectedRowKeys: Array<number>;
	};
	closeDialog: () => void;
	reload: () => void;
}

interface MultipleFormValue {
	id: Array<number>;
	value: number;
	extra?: number;
	batchPrice?: number;
	batchCount?: number;
}

export const validationSchema = Yup.object().shape({
	value: Yup.number().min(0, 'Negative price is prohibited'),
});

export const multipleEditModalId = -3;

export const MultipleEdit: React.FC<MultipleEditProps> = (props: MultipleEditProps) => {
	const {
		items, priceKind, closeDialog, modalItemId, rowSelection, reload,
	} = props;
	const crematory = useCrematory();
	const unit = WeightUnits[crematory?.units.weight ?? 0].slice(0, -1).toLowerCase();
	const init = items.filter((i) => rowSelection.selectedRowKeys.includes(i.id))
		.map((p) => ({
			value: p.value,
			extra: p.extra,
			batchCount: p.batchCount,
			batchPrice: p.batchPrice,
		}));
	const initialValues = init
		.reduce((acc: Partial<MultipleFormValue>, el) => {
			acc.value = acc.value === el.value ? acc.value : undefined;
			acc.extra = acc.extra === el.extra ? acc.extra : undefined;
			acc.batchCount = acc.batchCount === el.batchCount ? acc.batchCount : undefined;
			acc.batchPrice = acc.batchPrice === el.batchPrice ? acc.batchPrice : undefined;

			return acc;
		}, init[0]);

	return (
		<Formik
			initialValues={{ id: rowSelection.selectedRowKeys, ...initialValues }}
			validationSchema={validationSchema}
			onSubmit={(values, actions) => {
				const id = rowSelection.selectedRowKeys[0];
				const data = getShallowChanges({ ...initialValues, id }, { ...values, id });

				request('updatePriceList', { ...data, id: rowSelection.selectedRowKeys })
					.then(() => {
						alertMessage(MessageType.success, 'Success! The prices was updated');
						reload();
						rowSelection.onChange([]);
					})
					.catch((error) => alertMessage(MessageType.error, error))
					.finally(() => actions.setSubmitting(false));
			}}
			enableReinitialize
		>
			{(formikBag) => (
				<ModalWrapper
					title="Multiple Edit Prices"
					isOpened={Boolean(modalItemId === multipleEditModalId && rowSelection.selectedRowKeys.length)}
					onCancel={() => {
						closeDialog();
						formikBag.resetForm();
					}}
					onOk={formikBag.handleSubmit}
					okText="Save"
					width="50%"
				>
					<Form>
						<Row gutter={[30, 16]}>
							{(priceKind === PriceKind.ProductPrice || priceKind === PriceKind.UrnPrice || priceKind === PriceKind.SpecialServicePrice) && (
								<BatchPriceFields
									xs={24}
									sm={24}
									md={8}
									placeholders={{
										batchCount: !isPresent(formikBag.values.batchCount) ? 'Multiple Counts' : '',
										batchPrice: !isPresent(formikBag.values.batchPrice) ? 'Multiple Batch Prices' : '',
									}}
								/>
							)}
							<Col xs={24} sm={24} md={8}>
								<FormikField
									fieldName="value"
									title="Value of price*"
									inputProps={{
										type: 'number',
										min: 0,
										inputMode: 'decimal',
										placeholder: !isPresent(formikBag.values.value) ? 'Multiple Values' : '',
									}}
								/>
							</Col>
							{priceKind === PriceKind.BasePrice && (
								<Col xs={24} sm={24} md={8}>
									<FormikField
										fieldName="extra"
										title={`Price per ${unit}`}
										inputProps={{
											type: 'number',
											min: 0,
											inputMode: 'decimal',
											placeholder: !isPresent(formikBag.values.extra) ? 'Multiple Extra Values' : '',
										}}
									/>
								</Col>
							)}
						</Row>
					</Form>
				</ModalWrapper>
			)}
		</Formik>
	);
};
